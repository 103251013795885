.about-us__team-profile {
  max-width: 48rem;

  .about-us__titles {
    h4 {
      margin-top: 0;
    }
  }

  .about-us__img {
    max-height: 16rem;
    min-width: 16rem;
    width: 100%;
  }

  .about-us__bio-collapsed {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.about-us-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.about-us-column {
  flex-basis: 50%;
  margin: 0 auto;

  &:last-of-type {
    margin: inherit;
  }
}

@media only screen and (max-width: 1208px) {
  .about-us-column {
    flex-basis: 100%;
  }
}

@media only screen and (max-width: 810px) {
  .about-us__team-profile {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 550px) {
  .about-us__team-profile {
    flex-direction: column;
    .about-us__img {
      min-width: 14rem;
      width: 14rem;
      height: auto;
      margin-bottom: 1rem;
    }
    .about-us__bio {
      width: 100;
    }
  }
}

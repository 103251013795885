.admins-index-utils {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.page.current {
  display: inline;
}

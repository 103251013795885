.text-underline {
  text-decoration: underline;
}

.italicized-text {
  font-style: italic;
}

.uppercase-text {
  text-transform: uppercase;
}

.shamrock-text {
  color: $shamrock;
}

.thunderbird-text {
  color: $thunderbird;
}

.thunderbird-link,
.thunderbird-link .mdc-button__label {
  color: $thunderbird;
  cursor: pointer;

  &:hover {
    color: darken($thunderbird, 10%);
  }

  &:active {
    color: darken($thunderbird, 10%);
    text-decoration: none;
  }

  &:visited {
    color: lighten($thunderbird, 10%);
  }
}

.cornell-text {
  color: $cornell;
}

.cornell-link {
  color: $cornell;
  cursor: pointer;

  &:hover {
    color: darken($cornell, 10%);
  }

  &:active {
    color: darken($cornell, 10%);
    text-decoration: none;
  }

  &:visited {
    color: darken($cornell, 5%);
  }
}

.summer-sky-text {
  color: $summer-sky;
}

.middle-light-blue-text {
  color: $middle-light-blue;
}

.brand-red-text {
  color: $brand-red;
}

.default-text {
  color: $default;
}

.default-link {
  color: $default;

  &:hover {
    color: darken($default, 10%);
  }

  &:visited {
    color: lighten($default, 10%);
  }
}

.charcoal-text {
  color: $charcoal;
}

.brand-blue-text {
  color: $brand-blue;
}

.brand-blue-link {
  color: $brand-blue;
  cursor: pointer;

  &:hover {
    color: darken($brand-blue, 10%);
  }

  &:active {
    color: darken($brand-blue, 10%);
    text-decoration: none;
  }

  &:visited {
    color: darken($brand-blue, 5%);
  }
}

.white-text {
  color: $white;
}

.saffron-text {
  color: $saffron;
}

.casablanca-text {
  color: $casablanca;
}

.white-link {
  color: $white;

  &:hover {
    color: darken($white, 10%);
  }

  &:visited {
    color: lighten($white, 10%);
  }
}

.saffron-link {
  color: $saffron;

  &:hover {
    color: darken($saffron, 10%);
  }

  &:visited {
    color: lighten($saffron, 10%);
  }
}

.chambray-link {
  color: $chambray;
  cursor: pointer;

  &:hover {
    color: darken($chambray, 10%);
  }

  &:visited {
    color: lighten($chambray, 10%);
  }
}

.grey-link {
  color: $grey;
  cursor: pointer;

  &:hover {
    color: darken($grey, 10%);
  }

  &:visited {
    color: lighten($grey, 10%);
  }
}

.decoration-thunderbird {
  text-decoration-color: $thunderbird;
}

.decoration-none {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.text-xxs {
  font-size: 0.5rem;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
}

.text-6xl {
  font-size: 4rem;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.tracking-widest {
  letter-spacing: 0.1em;
}

.tracking-subheader {
  letter-spacing: 0.2em;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-lightbold {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-with-bars {
  align-items: center;
  display: flex;
  justify-content: center;

  &::before,
  &::after {
    background-color: var(--text-bar-color, #000);
    content: '';
    display: inline-block;
    flex-grow: 1;
    height: var(--text-bar-height, 1px);
  }

  &::before {
    margin-right: 15px;
  }

  &::after {
    margin-left: 15px;
  }

  &--left-aligned {
    justify-content: left;

    &::before {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    border-bottom: var(--text-bar-height, 1px) solid var(--text-bar-color, #000);
    border-top: var(--text-bar-height, 1px) solid var(--text-bar-color, #000);

    &--left-aligned {
      border-top: none;
    }

    &::before,
    &::after {
      display: none;
    }
  }
}

.dagr-profile-header {
  background-image: url('../../assets/images/landing_pages/rer-header-home-blue.jpg');
  background-color: $white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;

  .dagr-profile-pic-container {
    max-width: 12rem;
    img {
      border-radius: 50%;
    }
  }
}

.dagr-profile-cash-back-section {
  .dagr-profile-cash-back-calc {
    max-width: 35rem;
  }
}

.dagr-about-realtor-section {
  .dagr-about-realtor__img {
    max-width: 40rem;
  }
}

.dagr-read-my-reviews-section {
  .dagr-read-my-reviews__img {
    max-width: 38rem;
    box-shadow: 7px 8px 12px -3px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 7px 8px 12px -3px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 7px 8px 12px -3px rgba(0, 0, 0, 0.7);
  }
}

.dagr-additional-info {
  max-width: 30rem;
}

@media (max-width: 1024px) {
  .dagr-profile-header {
    .dagr-profile-header__container {
      flex-direction: column;

      .real-estate-rewards__wizard {
        margin: auto;
      }
    }
  }

  .dagr-profile-cash-back-section {
    .dagr-profile-cash-back-calc {
      max-width: 50rem;
      padding: 0;
      padding-top: 2rem;
    }
  }

  .dagr-about-realtor-section {
    flex-direction: column;

    .dagr-about-realtor__img {
      padding: 0;
      padding-top: 2rem;
    }
  }

  .dagr-read-my-reviews-section {
    flex-direction: column;
    .dagr-read-my-reviews__img {
      margin: auto;
      margin-top: 2rem;
    }
  }
}

.home-nav-container.dagr-page {
  .find-an-agent-link {
    display: none;
  }
}

@media (max-width: 752px) {
  .dagr-additional-infos {
    flex-direction: column;
    .dagr-additional-info {
      max-width: 100%;
      padding-bottom: 0.5rem;
    }
  }
}

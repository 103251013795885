.RecipientList {
  @include flexbox;
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include justify-content(flex-start);
  @include align-items(flex-start);
  list-style: none;

  li {
    @include flex(1, 0, 23%);
    background-color: $chambray;
    border-radius: 5px;
    color: #fff;
    margin-bottom: 5px;
    margin-right: 5px;
    max-width: 250px;
    padding: 2px;
    text-align: center;
    word-wrap: break-word;
  }
}

.InviteCreated {
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(center);
  @include align-items(center);
  background-color: $shamrock;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  color: #fff;
  font-size: 2rem;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;

  p {
    text-align: center;
  }
}

.invite-form {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(space-between);
  @include align-items(stretch);

  .invite-form__new-recipient {
    p {
      color: $default;
      font-weight: unset;
    }
  }
}

.add-recipient-input{
  flex-grow: 1;
  margin-right: 10px;
}

.avatar-preview {
  @extend %profile-image;
  height: auto;
  margin-bottom: 20px;
  width: 150px;
}

.file-dropzone {
  @include flexbox;
  @include flex-direction(column);
  @include flex-wrap(nowrap);
  @include justify-content(center);
  @include align-items(center);
  background: #F7F9FA;
  border: 2px dashed $muted-grey;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  max-width: 500px;
  min-height: 200px;
  padding: 20px;
  text-align: center;
}

.avatar-form {
  @include flexbox;
  @include flex-direction(column);
  @include flex-wrap(nowrap);
  @include justify-content(center);
  @include align-items(center);

  .avatar-button.saffron {
    background-color: $saffron;
    color: #000;
  }

  .avatar-button.chambray {
    background-color: $chambray;
    color: #fff;
  }

  .avatar-button.ui.button {
    width: 150px;
  }
}

.realtorscontroller {
  .avatar-form {
    max-width: 28rem;
    margin: auto;
  }
}

.avatar-buttons {
  @include flexbox;
  @include flex-direction(row);
  @include flex-wrap(nowrap);
  @include justify-content(space-around);
  @include align-items(center);
}

.pagination-list {
  list-style-type: none;
}

.pagination-list button {
  margin: 0.5rem;
  border: solid 1px #ddd;
  border-radius: 4px;
  width: 3rem;
  height: 2rem;
}

.bottom-pagination {
  .pagination-items-per-page {
    top: -130px !important;
  }
}

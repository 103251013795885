@use '@material/elevation';

.homepage-header {
  padding: 40px 2rem;
}

.header-content {
  justify-content: space-between;

  &__img {
    img {
      height: auto;
      width: 400px;
    }
  }
}

.homepage-content .homepage-title {
  margin-bottom: 1.5rem;
}

.homepage-offers {
  column-gap: 25px;
  display: grid;
  grid-auto-rows: minmax(300px, auto);
  grid-template-columns: repeat(auto-fill, minmax(200px, 300px));
  margin: 0 auto;
  max-width: 1200px;
  row-gap: 25px;
  width: 90vw;
}

.homepage-testimonials {
  width: 90vw;
}

.homepage-offer {
  @include elevation.elevation(1);

  &:hover {
    @include elevation.elevation(6);
  }

  a {
    color: $middle-light-blue;
    height: 100%;

    &:hover,
    &:visited,
    &:focus {
      color: $middle-light-blue;
      text-decoration: none;
    }
  }

  img {
    border-radius: inherit;
    object-fit: cover;
  }
}

/* Recent Blog Posts */
.blog-feed {
  list-style: none;

  .blog-post {
    @include elevation.elevation(1);

    &:hover {
      @include elevation.elevation(6);
    }
  }

  .blog-post-content {
    display: block;
  }
}

@media (min-width: 1025px) {
  .header-content {
    max-width: 1024px;
    width: 80%;
  }
}

@media (max-width: 885px) {
  .homepage-header {
    padding: 0;
  }

  .header-content {
    align-items: center;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 600px;
  }

  .header-content__cta {
    flex: 0 0 100%;
    padding: 40px 1rem;
  }

  .header-content__image {
    flex: 0 0 100%;
  }
}

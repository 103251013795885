.layout-footer {
  align-items: center;
  background-color: $middle-dark-blue;
  color: $white;
  display: flex;
  flex-direction: column;
  width: 100%;

  .footer-container {
    max-width: 1200px;

    .footer-header {
      h2 {
        max-width: 43rem;
      }
    }
  }

  hr {
    color: $mystic;
    opacity: 0.12;
  }

  .footer-nav-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    width: auto;
    margin-bottom: 20px;
    max-width: 750px;

    li {
      font-size: 16px;
      padding: 10px;

      a {
        color: $white;

        &:active,
        &:hover {
          text-decoration: none;
          color: darken($color: $white, $amount: 5);
        }
      }
    }
  }

  .footer-logo {
    margin-bottom: 20px;

    img {
      height: auto;
      width: 200px;
    }
  }

  .footer-social-list {
    display: flex;
    list-style: none;
    width: 95%;
    justify-content: space-around;
    margin-bottom: 25px;
    max-width: 300px;

    .social-link {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 50%;
      color: $white;
      padding: 7px 10px;
      &:active,
      &:hover {
        background-color: lighten($color: $middle-dark-blue, $amount: 5);
      }
    }
  }
}

.disclosure-section {
  background-color: $middle-dark-blue;
  div:first-of-type {
    color: white;
    max-width: 1200px;
    margin: 0 auto;
  }
  a {
    text-decoration: underline;
  }
}

.home-footer {
  .footer-divider {
    background-image: url('../../assets/images/footer-triangle.svg');
    background-size: 100% 120px;
    top: 0;
    height: 120px;
    z-index: 1;
    transform: rotateY(0) rotateX(0);

    @media (max-width: 1024px) {
      height: 80px;
      background-size: 100% 80px;
    }

    @media (max-width: 752px) {
      height: 50px;
      background-size: 100% 50px;
    }
  }

  .home-footer-links {
    .footer-quick-links {
      ul {
        list-style: none;
        text-align: left;
        max-width: 11rem;
        a {
          color: $white;
          &:hover {
            color: darken($color: $white, $amount: 10);
            text-decoration: none;
          }
        }
      }
    }

    .footer-social-div {
      width: 50%;
      .home-footer-logo {
        max-width: 30rem;
      }
    }

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;

      .footer-social-div {
        margin-top: 2rem;
        width: 100%;
        .home-footer-logo {
          max-width: 50rem;
        }
      }
    }

    @media (max-width: 752px) {
      .footer-quick-links {
        flex-wrap: wrap;
        ul {
          max-width: 15rem;
        }
      }
    }
  }
}

@media (max-width: 1010px) {
  .layout-footer {
    .footer-container {
      .footer-header {
        justify-content: center;
      }
    }
    hr,
    .footer-links {
      margin-right: auto;
      margin-left: auto;
      max-width: 42rem;
    }
  }

  .disclosure-section {
    div {
      max-width: 42rem;
    }
  }
}

@media (max-width: 635px) {
  .layout-footer {
    .footer-container {
      .footer-links {
        flex-direction: column-reverse;
        align-items: center;
      }
    }
  }
}

.MemberEvents {
  margin: 10px 0;

  .members-search-cancel {
    display: block;
    height: 22px;
    vertical-align: middle;
  }

  .events-dashboard-actions {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(space-between);
    @include align-items(center);
    @include flex-wrap(wrap);
  }

  .member-events-buttons {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(flex-end);
    @include align-items(center);

    .ui.button.saffron {
      margin-right: 15px;
    }
  }

  .exports-form {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(space-around);
    @include align-items(center);
    @include flex-wrap(wrap);
  }
}

@media only screen and (max-width: 480px) {
  .MemberEvents .exports-form {
    .ui.input {
      @include flex(0, 0, 49%);
    }

    .ui.button.export-button {
      margin: 20px 0 10px;
    }
  }
}

.wizard__step {
  display: none;

  &.wizard__step--active {
    display: block;
  }
}

.wizard__wizard-nav {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-start {
    justify-content: flex-start;
  }
}

.error-list {
  background-color: $thunderbird;
  border-radius: 5px;
  color: $white;
  font-size: 16px;
  list-style-position: inside;
  margin-bottom: 10px;
  padding: 10px 20px;

  &.error-list--empty {
    display: none;
  }
}

.rebate-errors {
  .error-list {
    margin: 2rem 2rem 0 2rem;
  }
}

@media (max-width: 485px) {
  .concierge-form {
    .wizard__wizard-nav {
      flex-direction: column;

      .mdc-ripple-upgraded {
        margin: 0.75rem 0;
      }
    }
  }
}

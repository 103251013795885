.bah-calculation-item {
  max-width: 35rem;
}

.bah-errors {
  ul {
    margin: 0 auto;
    margin-bottom: 1em;
  }
}

.bah-faq-section {
  max-width: 94rem;
}

.bah-calculator-header {
  background-image: url('../../assets/images/landing_pages/bah/new-bah-family-1200x675.jpg');
  background-size: contain;
  background-repeat: no-repeat;

  .bah-header-image {
    visibility: hidden;
  }

  .bah-calculator-form {
    background-color: $white;
    border-radius: 10px;
    max-width: 25rem;
    position: absolute;
    top: 20%;
  }
}

.bah-results-page_header {
  background-image: url('../../assets/images/landing_pages/bah/bah-result-header-house.jpg');
  background-position: 50%;
  background-size: 100%;
  background-size: cover;

  .submission-agreement {
    color: $white;
  }

  .bah-rer-cta {
    max-width: 16rem;
    border-radius: 5rem;
  }

  h1 {
    max-width: 35rem;
  }
}

.bah-results-video {
  max-width: 60rem;
}

@media only screen and (max-width: 768px) {
  .bah-calculator-header {
    background-image: none;
    background-color: $white;

    .bah-header-image {
      visibility: visible;
    }

    .bah-calculator-form {
      max-width: 25rem;
      margin: auto;
      position: initial;
    }
  }

  .bah-calculation {
    flex-direction: column;
    .bah-calculation-item {
      margin: 1rem auto;

      .button {
        width: 100%;
      }
    }
  }
}

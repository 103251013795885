.category-card.mdc-card {
  margin-bottom: 30px;
  width: 320px;
  border: 1px solid $muted-grey;
  border-radius: 0.5rem;

  .mdc-card__primary-action {
    min-height: 100%;
  }

  .category-card__image {
    height: auto;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    width: 100%;
  }

  .category-card__content {
    padding: 1.5rem 10px 0;
  }
}

.review-categories {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1150px;
}

.review-form-container {
  max-width: 50rem;
}

.review-card-avatar {
  max-width: 10rem;
  img {
    width: 100%;
  }
}

.review-section__card {
  background-color: $gradient-start;
}

.review-section__grade-table {
  padding: 1rem 2rem;
  tr {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.reviews-grade-bg {
  background-image: url('../../assets/images/reviews/review-legend.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 12rem;
  max-width: 24rem;
  resize: both;
}

.review-contest-header {
  color: $darker-grey;
  span {
    color: $thunderbird;
  }
}

.dagr-my-reviews-page {
  min-height: 100vh;
}

.review-section__nav-buttons {
  .ui.button.flex {
    display: flex;
  }
}

.review-section {
  .review-contest-disclosure {
    color: $default;
  }
  @media only screen and (max-width: 690px) {
    .review-section__nav-buttons {
      flex-direction: column-reverse;
      .ui.button {
        width: 100%;

        &.thunderbird {
          margin-bottom: 1rem;
        }
      }
    }

    .review-section__grade-table {
      padding: 1rem 0;
      td {
        padding: 0.25rem 0;
      }
    }
  }
}

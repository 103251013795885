@use '@material/elevation';

.pcs-button {
  @include elevation.elevation(1);
  color: $white;
  display: inline-block;
  text-decoration: none;
  transition: all 0.4s ease 0s;

  &:hover {
    @include elevation.elevation(6);
    color: $white;
    filter: brightness(115%);
    text-decoration: none;
  }

  &:visited {
    color: $white;
  }
}

form button[type='submit'].ui.button {
  &.chambray {
    background-color: $chambray;
    color: #fff;
  }

  &.summer-sky {
    background-color: $summer-sky;
    color: #fff;
  }

  &.cornell {
    background-color: $cornell;
    color: #fff;
  }

  &.thunderbird {
    background-color: $thunderbird;
    color: #fff;

    &:hover {
      background-color: darken($thunderbird, 10%);
    }
  }

  &.saffron {
    background-color: $saffron;
    color: $default;

    &:hover {
      background-color: lighten($saffron, 10%);
    }
  }
}

.ui.button.large {
  padding: 1.5rem;
}

.ui.button.chambray {
  background-color: $chambray;
  color: #fff;
}

.ui.button.shamrock,
.MuiButton-root.shamrock {
  background-color: $shamrock;
  color: #fff;

  &:hover {
    background-color: darken($shamrock, 5%);
  }
}

.ui.button.summer-sky {
  background-color: $summer-sky;
  color: #fff;
}

.ui.button.thunderbird,
.MuiButton-root.thunderbird {
  background-color: $thunderbird;
  color: #fff;

  &:hover {
    background-color: darken($thunderbird, 5%);
  }
}

.ui.button.cornell,
.MuiButton-root.cornell {
  background-color: $cornell;
  color: $white;

  &:hover {
    background-color: darken($cornell, 5%);
  }
}

.ui.button.brand-blue,
.MuiButton-root.brand-blue {
  background-color: $brand-blue;
  color: $white;

  &:hover {
    background-color: darken($brand-blue, 5%);
  }
}

.ui.button.casablanca,
.MuiButton-root.casablanca {
  background-color: $casablanca;
  color: #fff;

  &:hover {
    background-color: darken($casablanca, 5%);
  }

  &.glow {
    transition: all 300ms ease 0ms;
    box-shadow: 0px 10px 30px -10px #ffb144;
    -webkit-box-shadow: 0px 10px 30px -10px #ffb144;
    -moz-box-shadow: 0px 10px 30px -10px #ffb144;

    &:hover {
      box-shadow: 0px 10px 30px -10px darken(#ffb144, 5%);
      -webkit-box-shadow: 0px 10px 30px -10px darken(#ffb144, 5%);
      -moz-box-shadow: 0px 10px 30px -10px darken(#ffb144, 5%);
    }
  }
}

.ui.button.saffron {
  background-color: $saffron;
  color: $default;

  &:hover {
    background-color: lighten($saffron, 10%);
  }
}

.ui.button.mvc-yellow {
  background-color: $mvc-yellow;
  color: $default;

  &:hover {
    background-color: lighten($mvc-yellow, 10%);
  }
}

.ui.button.normal-blue {
  background-color: $normal-blue;
  color: $default;

  &:hover {
    background-color: lighten($normal-blue, 10%);
  }
}

.MuiButton-root {
  &.outlined-thunderbird {
    color: $thunderbird;
    border-color: $thunderbird;
    &:hover {
      color: darken($thunderbird, 5%);
      border-color: darken($thunderbird, 5%);
    }
  }
}

.MuiButton-root {
  &.outlined-brand-blue {
    color: $brand-blue;
    border-color: $brand-blue;
    &:hover {
      color: darken($brand-blue, 5%);
      border-color: darken($brand-blue, 5%);
    }
  }
}

@media (max-width: 752px) {
  .ui.button {
    &.sm-screen-full-w {
      width: 100%;
    }
  }
}

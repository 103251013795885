@mixin middle-dark-blue-gradient {
  background-image: linear-gradient(
    to bottom,
    #28337a,
    #303981,
    #383f89,
    #404690,
    #484c98,
    #4f529f,
    #5558a5,
    #5c5eac,
    #6465b4,
    #6b6cbb,
    #7374c3,
    #7b7bcb
  );
}

.blockquote {
  p {
    position: relative;
  }

  p::before {
    content: '“';
    position: absolute;
    font-size: 5rem;
    color: $summer-sky;
    font-weight: 300;
    left: -0.5em;
    top: -0.25em;
    line-height: 1;
  }

  p::after {
    content: '”';
    position: absolute;
    font-size: 5rem;
    color: $summer-sky;
    font-weight: 300;
    right: -0.4em;
    bottom: -0.7em;
    line-height: 1;
  }
}

.blockquote-content {
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.3));
  position: relative;

  &::after {
    content: '';
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    position: absolute;
    left: 50%;
    bottom: -15px;
    width: 30px;
    height: 20px;
    background-color: $white;
  }
}

.blockquote-cite {
  display: grid;
  column-gap: 10px;
  grid-template-areas:
    'image name'
    'image about';

  &__image {
    height: 75px;
    grid-area: image;
    width: 75px;
  }

  &__name {
    grid-area: name;
  }

  &__about {
    grid-area: about;
  }
}

.realtor-share-links {
  .share-link-input {
    color: $outer-space;
    border: solid $muted-grey 1px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;
  }

  .copy-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .share-link-container {
    max-width: 40rem;
  }

  .share-link-nav {
    height: fit-content;
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    max-width: 20rem;

    .share-nav-item {
      input {
        display: none;
      }
      color: $darker-grey;

      .share-nav-chevron {
        display: none;
      }

      input[type='radio']:checked + label {
        color: $thunderbird;
        .share-nav-chevron {
          display: inline;
        }
      }

      label:hover {
        cursor: pointer;
      }
    }
  }
  .share-link-nav-mobile {
    display: none;
  }
}

.copy-link {
  -webkit-box-shadow: 7px 6px 7px 3px #d5d5d5;
  box-shadow: 7px 6px 7px 3px #d5d5d5;
  border-radius: 5px;

  .share-link-input {
    color: $outer-space;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
    background-color: #f2f5f7;
    border: 0;
  }

  .copy-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
    background-color: $default;
    color: $white;

    &:hover,
    &:active {
      background-color: lighten($default, 10%);
      color: $white;
    }
  }
}

.lender-share-buttons-container {
  .lender-share-review-me {
    width: 100%;
    .copy-link {
    }
  }

  @media only screen and (max-width: 675px) {
    flex-direction: column;
    max-width: 40rem;
    margin: auto;
    .lender-share-buttons {
      max-width: 100%;
      a {
        width: 100%;
      }
    }

    .lender-share-review-me {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .realtor-share-links {
    justify-content: center;
    flex-direction: column;

    .share-link-container {
      max-width: 100%;
    }

    .share-link-nav {
      display: none;
    }
    .share-link-nav-mobile {
      display: block;
      border-radius: 5px;
    }
  }
}

@media only screen and (max-width: 690px) {
  .share-link-container {
    .review-me-edit-button {
      margin-left: 0;
      margin-top: 1rem;
      width: 100%;
    }
  }
}

.p-14 {
  padding: 14px !important;
}

.p-14 {
  .background-alice-blue {
    margin-top: 6px;
    margin-left: 16px;
  }
}

.pcs-date-year-label {
  position: relative;
  bottom: 4rem;
}

.pcs-date-dropdown:nth-of-type(2) {
  margin-top: 2rem;
}

.member-edit-buttons {
  margin-top: 20px;

  .ui.button {
    background-color: #fff;
    border: 2px solid $outer-space;
    color: $outer-space;

    &.submit-button {
      background-color: $thunderbird;
      color: #fff;
      border: 2px solid $thunderbird;
      padding: 11px 21px;
    }
  }
}

.member-lead-form-question {
  line-height: 24px;
}

.username-field {
  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        padding-bottom: 16px;
      }
    }
  }
}

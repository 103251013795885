.ServiceAddresses {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(space-between);
  @include align-items(center);
  margin-bottom: 20px;
  width: 100%;

  .light.well {
    width: 100%;
  }

  .list-actions {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(flex-end);
    @include align-items(center);
    width: 100%;
  }

  .action-button {
    @include flex(0, 0, 49%);
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    max-width: 100px;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &.save-button {
      background-color: $chambray;
    }

    &.add-address-button {
      background-color: $shamrock;
      margin-right: 20px;
    }
  }
}

.school-link-lh {
  line-height: 27px;
}

.school-table {
  margin-top: 1.5em;
  width: 100%;
  max-width: 100%;
  background-color: white;
  border-collapse: collapse;
  border: #ddd solid;
  border-radius: 4px;
}

.school-table td {
  height: 20px;
  max-width: 250px;
  padding: 4px 5px;
  overflow: hidden;
  line-height: 20px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  border-bottom: 1px solid #ddd;
}

.landing-pages-grid {
  display: flex;
  flex-direction: column;
}

body {
  section.site-section.rvb-content#content,
  section.site-section.homepage-content#content {
    margin: 0;
    width: 100%;
  }
}

.rvb-header {
  padding: 40px 2rem;
}

.rvb-cta {
  flex: 0 0 50%;
  max-width: 500px;
}

.rvb-image > img {
  height: auto;
  width: 400px;
}

.rvb-header__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.rvb-wizard__button {
  --mdc-theme-primary: #d91e18;
  font-size: 16px;
  height: 50px;
  min-width: 200px;

  &.rvb-wizard__button--start {
    font-size: 18px;
    height: 45px;
    padding: 0 50px;
  }

  &.outlined {
    background-color: $white;
    color: $thunderbird;
    border: $thunderbird solid 1px;
  }

  &.full-width {
    width: 100%;
  }
}

.password-tooltip-popup-container {
  max-width: 25rem;
  padding: 1rem;
  z-index: 1000;
}

.info-tooltip-icon {
  border: 2px $chambray solid;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: 600;
  }
}

.password-tooltip-popup {
  background-color: $mystic;
  border: solid 1px #000;
  border-radius: 5px;

  .info-tooltip-image {
    img {
      max-width: fit-content;
    }
  }
}

.react-datepicker.wizard-datepicker {
  font-size: 1rem;
}

.landing-page-section {
  padding: 40px 2rem;
}

.landing-page-section__content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: auto;

  &.landing-page-section__content--centered {
    flex-direction: column;
  }
}

.landing-page-section-image {
  flex: 0 0 50%;

  img {
    height: auto;
    width: 250px;
  }
}

.rvb-cta__tagline {
  margin: 0 0 30px;
}

.rvb-feature {
  margin-bottom: 20px;
}

.landing-page-section-title {
  font-weight: 400;
}

.rvb-feature__tagline {
  i {
    margin-right: 10px;
  }
}

.rvb-mobile-only-image {
  display: none;
  padding: 20px 10px;

  img {
    height: auto;
    max-width: 100%;
    width: 300px;
  }
}

.rvb-thanks-offerings {
  list-style-type: none;
  margin: auto;
  max-width: 850px;
  width: 90%;
}

.rvb-offering {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 16px;
  justify-content: space-between;
  margin-bottom: 50px;

  @media only screen and (max-width: 810px) {
    justify-content: space-around;

    .rvb-offering__image {
      margin-bottom: 20px;
    }
  }
}

.rvb-offering__image {
  flex: 0 0 30%;
  object-fit: contain;
}

.offering-content {
  flex: 0 0 68%;
  max-width: 425px;
}

.text-center.rvb-thanks-headline {
  margin: 30px 0;
}

.lp-how-it-works {
  align-items: center;
  justify-content: space-between;

  .lp-how-it-works__number {
    flex: 0 0 auto;
    height: 85px;
    width: 85px;
  }

  .lp-how-it-works__text {
    flex: 0 1 auto;
  }
}

.lp-fine-print {
  --text-bar-color: #2d3a8a;
  --text-bar-height: 3px;
}

.fine-print-item {
  align-items: center;
  justify-content: left;
}

.fine-print-item__icon {
  height: 34px;
  margin-right: 30px;
  width: 30px;
}

.helpful-link-box {
  width: 48%;
  border: 2px solid gainsboro;
  float: left;
  margin: 1%;
  text-align: center;
  padding-top: 17%;
  position: relative;
  min-height: 300px;
  border-radius: 8px;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: #387cb0;
    color: white;
    padding-top: 6%;

    &::after {
      opacity: 0;
    }

    a {
      text-decoration: none;

      h2 {
        color: white;
        text-decoration: none;
      }
    }

    p {
      opacity: 1;
    }

    .resource-button {
      opacity: 1;
      cursor: pointer;
      z-index: 1;
    }
  }

  &::after {
    opacity: 0.07;
    position: absolute;
    transition: opacity 300ms ease-in-out;
    z-index: -1;
  }

  &--blog::after {
    content: url('../images/comment.svg');
    left: 10%;
    top: 10px;
    width: 80%;
  }

  &--other-resources::after {
    content: url('../images/folder.svg');
    left: 10%;
    top: 10px;
    width: 80%;
  }

  &--media-links::after {
    content: url('../images/file-alt.svg');
    left: 20%;
    top: 15px;
    width: 60%;
  }

  &--faqs::after {
    content: url('../images/question-circle.svg');
    left: 10%;
    top: 10px;
    width: 80%;
  }

  a {
    text-decoration: none;

    h2 {
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 5px;
      text-decoration: none;
      color: black;
    }
  }

  p {
    color: white;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
    opacity: 0;
  }

  .resource-button {
    opacity: 0;
    width: 75%;
    text-transform: uppercase;
    font-weight: 400;
    padding: 14px 0px;
    font-size: 1.1rem;
    background-color: white;
    color: #387cb0;
    font-weight: 800;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    z-index: 1;
  }
}

.full-screen-width {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  margin-top: 50px;
  left: 50%;
}

.rer-bg-dark-blue {
  background-color: #2b3c8a;
}

.rer-bg-light-blue {
  background-color: #f2f4fa;
}

.rer-dark-blue-text {
  color: #2b3c8a;
}

.rer-white-text {
  color: white;
}

.rer-header {
  font-weight: bold;
  font-size: 2.5rem;
}

.rebate-header {
  font-size: 3.5rem;
  font-weight: bold;
}

.rebate-sub-header {
  font-size: 2.2rem;
}

.how-it-works-header {
  font-size: 4rem;
}

.how-it-works-section {
  display: flex;
  justify-content: space-around;
  padding: 2rem;

  .how-works-card {
    max-width: 12rem;
    margin: 0 1rem;
    h2 {
      font-weight: bold;
      font-size: 8rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
}

.rer-rebate-estimator {
  display: flex;
  justify-content: center;
  padding: 4rem;

  .rer-calculator {
    padding: 2rem;
    max-width: 30rem;
    margin-right: 5rem;
  }

  .rer-rebate-estimator-text {
    color: white;
    max-width: 28rem;

    .rer-cash-back-header {
      font-weight: bold;
      font-size: 5rem;
    }

    .rer-cash-back-paragraph {
      line-height: 1.5;
      font-size: 1.8rem;
    }
  }
}

.rer-questions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;

  .questions-text {
    max-width: 30rem;
    margin-right: 5rem;
    h2 {
      font-weight: bold;
      font-size: 5rem;
    }

    p {
      line-height: 1.5;
      font-size: 1.8rem;
    }
  }
}

.rer-testimonial {
  max-width: 70rem;
  padding-top: 2rem;

  .testimonial {
    text-align: center;

    h2 {
      font-size: 3rem;
    }

    p {
      line-height: 1.5;
      font-size: 1.8rem;
    }
  }

  .testimonial-name {
    margin-top: 1rem;

    padding: 1rem;
    h2 {
      margin-left: auto;
      font-weight: bold;
      max-width: 23rem;
    }
  }
}

.rer-white-bg {
  background-color: $white;
}

.rebate-section {
  .rebate-input {
    min-width: 10rem;
    max-width: 85%;

    &.buy-sell {
      margin: 0 0 1rem;
    }

    .ui.basic.label {
      background-color: $brand-blue;
      color: $white;
    }

    input {
      &::placeholder {
        color: $outer-space;
      }
    }
  }

  .rebate-calc-header {
    p {
      padding: 2rem;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
}

.rer-partners {
  display: flex;
  justify-content: space-around;
  padding: 2rem;
  flex-wrap: wrap;

  img {
    margin: 1rem;
  }
}

#wizard-step-one::before {
  display: block;
  content: ' ';
  margin-top: -190px;
  height: 190px;
  visibility: hidden;
}

#wizard-step-one:target {
  border: solid gold 2px;
  padding: 1em;
}

#concierge-get-started::before {
  display: block;
  content: ' ';
  margin-top: -190px;
  height: 190px;
  visibility: hidden;
}
#concierge-get-started:target {
  border: solid gold 4px;
  padding: 1em;
}

.phone-num-no-wrap {
  overflow: hidden;
  white-space: nowrap;
}

.alert-banner-container {
  width: 100%;
  background-color: #0091ea;
  color: $white;
  font-size: 16px;

  p {
    line-height: 1.2;
    margin-bottom: 0;
  }

  .alert-banner-icon {
    font-size: 2rem;
  }

  .banner-close {
    color: white;
    position: relative;
    top: -6px;
    font-size: 1.3rem;
    padding-left: 2rem;
  }

  .banner-url {
    color: white;
    text-decoration: underline;
  }
}

.loading-section {
  font-size: 3rem;
  padding-top: 7rem;
}

.loading-rotation {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rer-header__pic {
  margin-left: 1rem;
}

@media (min-width: 1025px) {
  .rvb-header__content,
  .landing-page-section__content {
    max-width: 1024px;
    width: 80%;
  }
}

@media (max-width: 885px) {
  .rvb-mobile-only-image {
    display: flex;
  }

  .rvb-header,
  .landing-page-section {
    padding: 0;

    &.pt-8 {
      padding-top: 2rem;
    }
  }

  .rvb-header__content,
  .landing-page-section__content {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 600px;
  }

  .landing-page-section__content {
    padding: 20px 0;
  }

  .rvb-cta,
  .landing-page-section-headline {
    flex: 0 0 100%;
    padding: 40px 1rem;
  }

  .rvb-image,
  .landing-page-section-image {
    flex: 0 0 100%;
  }

  .rer-rebate-estimator {
    flex-direction: column;
    padding: 5rem;

    .rer-calculator {
      margin: 0 auto;
    }

    .rer-rebate-estimator-text {
      margin: 0 auto;
      margin-top: 1rem;
      max-width: 30rem;
    }
  }

  .rer-questions {
    flex-direction: column-reverse;

    .questions-text {
      margin: 0 auto;
      margin-top: 1rem;
      max-width: 400px;
    }

    .questions-img {
      margin: 0 auto;
    }
  }

  .rer-header__pic {
    margin-left: 0;
  }
}

.questions-img-height {
  max-width: 400px;
}

.campaigns-list {
  list-style: none;
}

@media (max-width: 480px) {
  .lp-how-it-works {
    .lp-how-it-works__number {
      height: 60px;
      width: 60px;
    }
  }
  .how-it-works-section {
    flex-direction: column;
    margin: 0 auto;
  }

  .questions-img-height {
    max-width: 300px;
  }

  .rer-rebate-estimator {
    padding: 0 1rem;
  }

  .rer-header {
    font-size: 1.5rem;
  }

  .rer-rebate-estimator-padding {
    padding: 3rem 2rem;
  }

  .rer-rebate-estimator {
    .rer-rebate-estimator-text__welcome-page {
      padding-bottom: 2rem;
      margin-top: 0;
      .rer-cash-back-header {
        font-size: 3rem;
      }

      .rer-cash-back-paragraph {
        font-size: 1rem;
      }
    }
  }

  .rer-questions {
    .questions-text {
      h2 {
        font-size: 3rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }

  .home-header.home-header-mobile {
    font-size: 3rem;
  }
}

.no-reviews-tab {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(space-between);
  @include align-items(center);
  margin-top: 20px;

  p {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .ui.button.thunderbird.review-section-button {
    display: block;
    font-size: 20px;
    width: 300px;
  }
}

.color-picker-preview {
  background: var(--color-picker-preview, #000);

  &.brokerage-index {
    max-width: 1rem;
  }
}

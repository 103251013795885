@use '@pcs-grades/design-core/src/global';

// Tell "font-faces" where to find our font files
@include global.font-faces('../fonts');

.trix-content *,
.ui.button.fs-base {
  @include global.text-size(base);
}

.fs-sm,
.fs-base,
.fs-lg {
  &.font-semibold {
    font-weight: 600;
  }
}

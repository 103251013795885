.appearance-none {
  appearance: none;
}

.inline-list {
  @include flexbox;
  @include flex-direction(row);
  list-style: none;
}

.list-style-none {
  list-style: none;
}

.list-inside {
  list-style-position: inside;
}

.flex-centered {
  align-items: center;
  display: flex;
  justify-content: center;
}

.m-auto {
  margin: auto;
}

.m-0-auto {
  margin: 0 auto;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 0.75rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-8 {
  margin: 2rem;
}

.m-12 {
  margin: 3rem;
}

.m-16 {
  margin: 4rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mr-auto {
  margin-right: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-1 {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.mx-2 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.mx-3 {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.mx-4 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.mx-6 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.mx-8 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.my-auto {
  margin-bottom: auto;
  margin-top: auto;
}

.my-0 {
  margin-bottom: 0;
  margin-top: 0;
}

.my-1 {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.my-2 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.my-3 {
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}

.my-4 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.my-8 {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.my-12 {
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.my-16 {
  margin-bottom: 4rem;
  margin-top: 4rem;
}

.max-w-xs {
  max-width: 8rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-1200 {
  max-width: 1200px;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-32 {
  width: 8rem;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-56 {
  width: 14rem;
}

.w-64 {
  width: 16rem;
}

.w-auto {
  width: auto;
}

.w-px {
  width: 1px;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-full {
  width: 100%;
}

.h-fit {
  height: fit-content;
}

.min-h-full {
  min-height: 100vh;
}

.w-fit-content {
  width: fit-content;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-imp {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-16 {
  padding: 4rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.py-1 {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

.py-2 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.py-3 {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.py-4 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.py-5 {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}

.py-6 {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.py-8 {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.py-10 {
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
}

.py-12 {
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.py-16 {
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.py-20 {
  padding-bottom: 5rem;
  padding-top: 5rem;
}

.py-24 {
  padding-bottom: 6rem;
  padding-top: 6rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-16 {
  padding-top: 4rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.rounded {
  border-radius: 0.5rem;
}

.rounded-top {
  border-radius: 0.5rem 0.5rem 0 0;
}

.pointer-events-none {
  pointer-events: none;
}

.flex {
  display: flex;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-none {
  flex: none;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-end {
  align-items: flex-end;
}

.items-start {
  align-items: flex-start;
}

.justify-start {
  justify-content: flex-start;
}

.justify-start-imp {
  justify-content: flex-start !important;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.fill-current {
  fill: currentColor;
}

.background-mystic {
  background-color: $mystic;
}

.background-white {
  background-color: $white;
}

.background-alice-blue {
  background-color: $alice-blue;
}

.background-thunderbird {
  background-color: $thunderbird;
}

.background-chambray {
  background-color: $chambray;
}

.background-outer-space {
  background-color: $outer-space;
}

.bg-grad-mid-dark-blue {
  @include middle-dark-blue-gradient;
}

.border-muted-grey {
  border-color: $muted-grey;
}

.border-white {
  border-color: $white;
}

.border-outer-space {
  border-color: $outer-space;
}

.circular-border {
  border-radius: 50%;
}

.no-wrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

@media (max-width: 1024px) {
  .mobile-hide-1024 {
    display: none;
  }
}

.experience-card {
  max-width: 22rem;
}

.realtor-achievements {
  h3 {
    margin-bottom: 0;
  }
}

.vendor-profile-section {
  margin-bottom: 4rem;
  padding-bottom: 4rem;
  border-bottom: $muted-grey solid 1px;
}

.vendor-profile-review {
  border-bottom: $muted-grey solid 1px;
}

.realtor-profile-m-width {
  max-width: 1200px;
  margin: auto;
}

.realtor-img-card {
  max-width: 30rem;
  border-bottom: solid 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: fit-content;
  -webkit-box-shadow: 3px 5px 6px 0px #d6d6d6;
  box-shadow: 3px 5px 6px 0px #d6d6d6;

  &.internal-profile {
    max-width: 100%;

    .pic-container {
      img {
        max-width: 8rem;
      }
    }

    .pic-container__mobile {
      display: none;
    }

    .realtor-img-card__body {
      img {
        max-width: 45%;
      }
    }

    .vendor-information {
      max-width: 90%;
    }

    .vendor-profile-card-button {
      max-width: 27rem;
      width: 100%;

      &.half-width {
        .button {
          width: 100%;
        }
      }

      &.neighborhood-button {
        max-width: 14rem;
      }
    }

    .ReferralWizard {
      padding: 0;
      box-shadow: none;
    }

    .mdc-dialog--open {
      z-index: 1000;
    }

    .show-on-vendor-profile {
      display: block;
    }

    .hide-on-vendor-profile {
      display: none;
    }

    .MemberReferral.complete.concierge-form {
      display: flex;
      flex-direction: column;

      .m-auto {
        margin: auto;
      }
    }
  }

  &.century-gold {
    border-bottom-color: #beaf88;
  }

  &.coldwell-blue {
    border-bottom-color: #012169;
  }

  &.berkshire-purple {
    border-bottom-color: #53174d;
  }

  &.better-homes-green {
    border-bottom-color: #439639;
  }

  &.corcoran-black {
    border-bottom-color: #000;
  }

  &.era-red {
    border-bottom-color: #da1a32;
  }

  &.grace-rudd-papadopoulos-blue {
    border-bottom-color: #40a8be;
  }

  &.keller-williams-red {
    border-bottom-color: #c33641;
  }

  &.lynn-realty-group-blue {
    border-bottom-color: #045ea2;
  }

  &.realty-executives-blue {
    border-bottom-color: #2a415e;
  }

  &.sentry-blue {
    border-bottom-color: #084166;
  }

  &.samson-black {
    border-bottom-color: #231f20;
  }

  &.sothebys-blue {
    border-bottom-color: #002349;
  }

  &.company-color {
    border-bottom-color: var(--company-color, #000);
  }

  &.default-grey {
    border-bottom-color: #a9acc1;
  }

  &.corvias-blue {
    border-bottom-color: #014ea8;
  }

  &.realtor-img-card__mobile {
    display: none;
  }

  &.signed-in-realtor {
    min-height: 54rem;
  }
}

.vendor-grades {
  color: $white;
  text-align: center;
  border-radius: 5px;
  font-weight: 400;
  font-size: 4rem;
  padding: 2rem 1rem;
  background-color: $outer-space;

  &.A,
  &.A\+ {
    background-color: $grade-a-green;
  }

  &.B,
  &.B\+ {
    background-color: $grade-b-blue;
  }

  &.C,
  &.C\+ {
    background-color: $grade-c-yellow;
  }

  &.D,
  &.D\+ {
    background-color: $grade-d-orange;
  }

  &.F,
  &.F\+ {
    background-color: $grade-f-red;
  }
}

.realtor-profile-section {
  max-width: 60rem;

  hr {
    color: $mystic;
  }

  .realtor-profile__collapsed {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .vendor-contact-card {
    max-width: 25rem;
    td {
      max-width: 18rem;
    }
  }

  .tab-small {
    background-color: $white;
  }
}

.realtor-profile__name-container {
  max-width: 1320px;
}

.realtor-invite-header {
  background-image: url('../../assets/images/realtors/blue-suburb.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;

  .realtor-invite-header__text {
    max-width: 50rem;
  }

  .main-page-m-width {
    max-width: 1200px;
  }

  .realtor-picture-container {
    max-width: 100px;

    .realtor-badges {
      position: relative;
      bottom: 20px;
    }

    .realtor-profile-pic {
      img {
        border-radius: 50%;
      }
    }
  }
}

.profile-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'profile-pic about-info about-info about-info grade'
    'buttons buttons buttons buttons buttons'
    'hr hr hr hr hr'
    'logos logos . . .';

  .vendor-avatar {
    grid-area: profile-pic;

    img {
      max-width: 14rem;
      -webkit-box-shadow: 3px 5px 6px 0px #d6d6d6;
      box-shadow: 3px 5px 6px 0px #d6d6d6;
    }
  }

  .vendor-info {
    grid-area: about-info;
  }

  .vendor-grade {
    grid-area: grade;
    .vendor-grades {
      min-width: 7rem;
    }
    .vendor-badges {
      display: none;
    }
  }

  .vendor-buttons {
    grid-area: buttons;
  }

  .vendor-card-hr {
    grid-area: hr;
  }

  .company-logos {
    grid-area: logos;
    max-width: 24rem;

    .rmr-logo {
      max-height: 4rem;
    }
  }

  .contact-information {
    p {
      margin: 0;
    }
  }

  .contact-icon {
    font-size: 2rem;
    color: $brand-blue;
  }
}

.realtor-relocating-section {
  max-width: 80rem;
}

.realtor-profile__how-profile-appears {
  background-color: #2185d0;
  border-radius: 5px;
}

.profile-overall-grade {
  width: 4rem;
}

.review-overall-grade {
  width: 3rem;
}

.average-grade {
  width: 2rem;
}

.vendor-review-section {
  .review-bg {
    &:nth-child(odd) {
      background-color: #f7f9fa;
    }

    &:nth-child(even) {
      background-color: $white;
    }
  }

  .average-grade-bg {
    &:nth-child(even) {
      background-color: #f7f9fa;
    }

    &:nth-child(odd) {
      background-color: $white;
    }
  }
}

.realtor-reviews__collapsed {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.realtor-reviews-grades__collapsed {
  display: none;
}

.grade-description-tooltip-popup {
  background-color: $mystic;
  border: solid 1px #000;
  border-radius: 5px;
  max-width: 25rem;
}

.show-on-vendor-profile {
  display: none;
}

.vendor-reply {
  .mobile-vendor-avatar {
    display: none;
  }
}

.vendor-reply-submit {
  min-width: 10rem;
}

@media (max-width: 1024px) {
  .realtor-invite-header {
    .main-page-section {
      flex-direction: column;

      .realtors-header-logo-section {
        padding-top: 0;
      }
    }
  }
}

@media (max-width: 885px) {
  .realtor-profile-container {
    background-color: $white;
    flex-direction: column-reverse;
    margin-left: 0;

    .realtor-profile__realtor-buttons {
      padding-left: 0;
      padding-bottom: 0;
    }

    .realtor-img-card {
      height: fit-content;
      padding: 0 2rem;
      background-color: $white;
      width: fit-content;

      &.signed-in-realtor {
        min-height: 0;
      }

      .pic-container {
        width: fit-content;
        padding: 0;
      }

      &.mobile-border {
        display: none;
      }

      &.realtor-img-card__mobile {
        display: block;
        max-width: 90%;
        background-color: #f7f9fa;
        padding: 0;

        .realtor-img-card__body {
          display: block;
        }
      }

      .realtor-img-card__body {
        display: none;
      }
    }
  }

  .realtor-img-card {
    &.internal-profile {
      .pic-container {
        display: none;
      }

      .pic-container__mobile {
        display: block;
        img {
          max-width: 5rem;
        }
      }

      .vendor-information {
        max-width: 100%;
      }
    }
  }

  .profile-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'profile-pic grade'
      'about-info about-info'
      'buttons buttons'
      'hr hr'
      'logos logos';

    .vendor-avatar {
      text-align: left;
      margin-right: 1rem;
      img {
        max-width: 12rem;
      }
    }

    .vendor-info {
      padding: 1rem 0;

      .vendor-badges {
        display: none;
      }
    }

    .vendor-grade {
      margin-top: 0;
      .vendor-badges {
        display: inherit;
        margin-top: 0.5rem;
        img {
          max-width: 1.8rem;
        }

        .password-tooltip-popup {
          font-size: 14px;
        }
      }
    }

    .company-logos {
      max-width: 100%;
    }
  }
}

.vendor-profile-card-buttons {
  .mdc-dialog__container {
    width: 100%;
    .mdc-dialog__surface {
      width: 100%;
    }
  }
}
@media (max-width: 680px) {
  .profile-grid-container {
    .vendor-buttons {
      .vendor-profile-card-buttons {
        flex-direction: column;
        .vendor-profile-card-button {
          max-width: 100%;
          &.half-width {
            margin: 0.5rem 0;
          }
        }
      }
    }
  }

  .vendor-reply {
    .vendor-reply__header {
      margin-bottom: 1rem;

      .mobile-vendor-avatar {
        display: inline;
        img {
          max-width: 4rem;
        }
      }
    }
    .vendor-avatar {
      display: none;
    }
  }
}

@media (max-width: 550px) {
  .realtor-profile-container {
    .realtor-img-card {
      .brands-div {
        justify-content: center;
        flex-direction: column-reverse;
      }
    }
  }
}

@media (max-width: 400px) {
  .profile-grid-container {
    .vendor-avatar {
      img {
        max-width: 10rem;
      }
    }
  }
}

.middle-dark-blue-bg {
  background-color: $middle-dark-blue;
}

.lost-during-pcs-header {
  .lost-during-pcs-header_main {
    max-width: 1400px;
  }

  .powered-by-pcsgrades-logo {
    max-width: 15rem;
  }

  .lost-during-pcs-header__fms {
    max-width: calc(20% - 10px);
  }
}

.find-my-stuff {
  font-size: 100px;
  color: $casablanca;
  white-space: nowrap;
  writing-mode: vertical-rl;
  text-orientation: mixed;

  &.fms-down {
    margin-left: 6rem;
  }

  &.fms-up {
    transform: rotate(180deg);
    margin-right: 9rem;
  }
}

.lost-during-pcs-description {
  max-width: 30rem;
}

.lost-during-pcs-how-it-works {
  box-shadow: inset -40vw 0px 0px 0px $casablanca;

  .lost-during-pcs-how-it-works__lg-text {
    font-size: 100px;
    line-height: 8rem;
  }

  .lost-during-pcs-how-it-works__description {
    min-width: 27rem;

    .mobile-show {
      display: none;
    }
  }

  .lost-during-pcs-how-it-works__steps {
    max-width: 40rem;
    color: rgba(255, 255, 255, 0.5);

    .mb-12 {
      min-height: 5rem;
    }

    .lost-during-pcs-how-it-works__step {
      font-size: 150px;
      font-family: sans-serif;
    }
  }
}

.brought-to-you-by-pcsgrades {
  max-width: 30rem;
  p {
    padding-right: 3rem;
    margin-bottom: -1rem;
    font-weight: 600;
  }
}

.lost-during-pcs-help-by-reviewing {
  max-width: 40rem;
}

.lost-during-pcs-reuinited {
  .lost-during-pcs-reuinite__divider {
    background-image: url('../../assets/images/landing_pages/lost_during_pcs/reunited-triangle.svg');
    background-size: 100% 120px;
    top: 0;
    height: 120px;
    z-index: 1;
    transform: rotateY(0) rotateX(0);

    @media (max-width: 1024px) {
      height: 80px;
      background-size: 100% 80px;
    }

    @media (max-width: 752px) {
      height: 50px;
      background-size: 100% 50px;
    }
  }

  .lost-during-pcs-reuinited__content {
    width: 100%;
    max-width: 55rem;
    transform: translateX(-12%);

    .lost-during-pcs-reuinited__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;

      .lost-during-pcs-reuinited__grid-item {
        background-color: #eaeaea;
      }
    }
  }
}

.lost-during-pcs-footer {
  background-color: #2e2e2e;

  .transparent-white-text {
    color: rgba(255, 255, 255, 0.5);
  }

  .lost-during-pcs-footer-chevrons {
    font-size: 5rem;
  }

  .lost-during-pcs-footer-logo {
    max-width: 18rem;
  }

  .lost-during-pcs-footer-mission {
    max-width: 36rem;
  }

  .lost-during-pcs-footer-subscribe {
    .MuiTextField-root.mui-text-field {
      fieldset,
      label,
      .MuiInputAdornment-outlined {
        border-color: $casablanca;
        color: $casablanca;
      }

      input {
        color: $white;
      }

      .Mui-error {
        fieldset,
        .MuiInputAdornment-outlined {
          border-color: #d32f2f;
          color: #d32f2f;
        }
      }

      label.Mui-error {
        color: #d32f2f;
      }
    }
  }

  .lost-during-pcs-footer-socials {
    a {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 50%;
      color: #fff;
      padding: 11px 15px;
      font-size: 1.5rem;
      background: #252525;
      &:active,
      &:hover {
        background-color: lighten($color: #252525, $amount: 5);
      }
    }
  }
}

@media (max-width: 1024px) {
  .lost-during-pcs-header {
    .lost-during-pcs-header_main {
      flex-direction: column;
    }

    .lost-during-pcs-header__fms {
      max-width: 15rem;

      .find-my-stuff {
        font-size: 100px;
        &.fms-down {
          margin: auto;
        }
      }
    }
  }

  .lost-during-pcs-reuinited {
    .lost-during-pcs-reuinited__content {
      .lost-during-pcs-reuinited__grid {
        grid-template-columns: 1fr;
      }
    }
  }

  .lost-during-pcs-footer-info {
    flex-direction: column;
    .lost-during-pcs-footer-chevrons {
      margin: auto;
      max-width: 26rem;
      margin-bottom: 4rem;
      margin-top: 2rem;

      .lost-during-pcs-footer-logo {
        max-width: 34rem;
      }
    }

    .lost-during-pcs-footer-mission {
      padding: 0;
      max-width: 100%;
    }

    .lost-during-pcs-footer-subscribe {
      margin-top: 2rem;
    }
  }
}

@media (max-width: 752px) {
  .lost-during-pcs-header__fms {
    display: none;
  }

  .lost-during-pcs-header__cta {
    margin-right: 0;
    width: 100%;
  }

  .find-my-stuff {
    display: none;
  }

  .lost-during-pcs-about {
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
    .lost-during-pcs-description {
      max-width: 100%;
      margin-top: -0.5rem;
    }
  }

  .lost-during-pcs-how-it-works-container {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;

    .lost-during-pcs-how-it-works__list-items {
      padding-left: 1rem;
    }

    .lost-during-pcs-how-it-works__description {
      min-width: 0;
      padding-top: 0;
      padding-right: 0;

      .mobile-hide {
        display: none;
      }

      .mobile-show {
        display: block;
      }
    }

    .lost-during-pcs-how-it-works__steps {
      max-width: 100%;
    }
  }

  .lost-during-pcs-reuinited {
    .lost-during-pcs-reuinited__content {
      transform: translateX(0);
    }
  }

  .lost-during-pcs-footer-container {
    flex-direction: column;
    .py-8 {
      padding-bottom: 0;
    }

    .p-8 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@use '@material/button';
@use '@material/elevation';

.home-nav-container {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(flex-start);
  @include align-items(center);
  background-color: $white;
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.61);
  position: sticky;
  top: 0;
  z-index: 10;

  &.home-page {
    box-shadow: none;

    @media (max-width: 1024px) {
      box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.61);
    }
  }
}

.how-it-works-step {
  max-width: 16rem;
  margin: 0 auto;
  font-size: 1.4rem;
  margin-top: 1rem;
}

.home-rebate-calc {
  margin-top: 5rem;
}

.home-header {
  font-size: 4.5rem;
  font-weight: bold;
}

.home-drawer-actions {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 90%;

  .nav-menu-button {
    margin-top: 20px;
    width: 100%;
  }
}

.phone-nav-link {
  height: 30px;
  border: solid 0.5px;
  border-radius: 3px;
}

.nav-menu-button {
  &.log-in-button {
    @include button.outline-color($white);
    --mdc-theme-primary: #fff;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }
}

.TopLineNav.home-nav {
  border-bottom: 0;
  margin-bottom: 0;
  max-width: 1200px;

  @media (max-width: 1024px) {
    margin-bottom: 0;
    padding: 10px 25px;
  }

  @media (max-width: 480px) {
    padding: 10px 15px;

    .members-logo {
      width: 160px;
    }
  }

  @media (max-width: 350px) {
    padding: 10px 5px;
  }
}

// HEADER SECTION
.home-header-section {
  background-position: center left;
  background-image: radial-gradient(
      circle at top left,
      rgba(255, 255, 255, 0.89) 30%,
      rgba(255, 255, 255, 0) 80%
    ),
    url('../../assets/images/home/home-page-family.jpg');
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: 100%;
  background-size: cover;

  padding: 20rem 0 14rem;

  @media (max-width: 752px) {
    background-image: radial-gradient(
        circle at top left,
        rgba(255, 255, 255, 0.89) 30%,
        rgba(255, 255, 255, 0.66) 80%
      ),
      url('../../assets/images/home/home-page-mobile-family.jpg');
    padding: 5rem 0;
  }
}

// SECONDARY NAV SECTION
.home-secondary-nav {
  @include elevation.elevation(4);

  position: sticky;
  top: 70px;
  z-index: 8;
  max-width: 94%;
  padding-left: 14vw;
  margin-top: -3.85rem;

  .secondary-nav-item {
    background: $white;
    &:hover {
      text-decoration: none;
      background-color: darken($white, 10%);
    }
  }

  .home-sign-up {
    background-color: $cornell;
    color: white;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      text-decoration: none;
      background-color: darken($cornell, 10%);
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.home-mobile-sign-up {
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
}

// REVIEW CATEGORIES SECTION
.review-categories-section {
  .review-categories {
    justify-content: space-between;
  }

  @media (max-width: 1024px) {
    padding-top: 2rem;
    .review-categories {
      justify-content: space-around;
    }
  }

  @media (max-width: 752px) {
    .review-categories {
      .category-card {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

// RECENT REVIEWS SECTION
.recent-reviews-section {
  .recent-review-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 1rem;
    column-gap: 4rem;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      .recent-review {
        max-width: 55rem;
        margin: auto;
      }
    }
  }
}

.recent-review-section__with-image {
  .recent-review-grid {
    grid-template-areas:
      'review1 image'
      'review2 image';
  }

  .recent-reviews-section__review1 {
    grid-area: review1;
  }

  .recent-reviews-section__review2 {
    grid-area: review2;
  }

  .recent-reviews-section__image {
    grid-area: image;
  }

  @media (max-width: 1024px) {
    .recent-review-grid {
      grid-template-areas:
        'review1'
        'review2'
        'image';
    }
  }
}
// VIDEO SECTION
.video-and-reviews-section {
  .image-gallery-icon {
    padding-right: 2rem;
  }

  .image-gallery {
    .image-gallery-thumbnail {
      margin-top: 1rem;
      width: 12rem;

      margin-right: 1rem;
      &:last-of-type {
        margin-right: 0;
      }

      @media (max-width: 752px) {
        width: 8rem;
        margin-right: 0.5rem;
      }
    }
  }
}

// CHECKLIST DOWNLOAD SECTION
.checklist-download-section {
  background-position: center right;
  background-image: radial-gradient(
      circle at top right,
      rgba(255, 255, 255, 0) 0%,
      $white 60%
    ),
    url('../../assets/images/home/woman-with-clipboard.jpg');
  box-shadow: inset 80px -80px 80px -40px $white;
  position: relative;
  background-color: $white;
  background-position: 50%;
  background-size: 100%;
  background-size: cover;

  &#claims-guide-section {
    background-image: radial-gradient(
        circle at top right,
        rgba(255, 255, 255, 0) 0%,
        $white 60%
      ),
      url('../../assets/images/landing_pages/lost_during_pcs/claims-guide-boxes.jpg');
  }

  .checklist-download {
    max-width: 35rem;

    @media (max-width: 752px) {
      max-width: 100%;
    }
  }
}

// PCS STORY SECTION
.pcs-story-section {
  .pcs-story-div {
    width: 47.25%;

    .pcs-story-text {
      max-width: 40rem;
    }
  }

  @media (max-width: 850px) {
    flex-direction: column;

    .pcs-story-div {
      width: 100%;
      .pcs-story-text {
        max-width: 100%;
      }
    }

    &.save-on-home-section {
      padding-top: 0;

      .pcs-story-text {
        padding-top: 2rem;
        padding-left: 2rem;
      }
    }
  }
}

// TRENDING BASES SECTION
.top-trending-bases-section {
  .trending-bases-cards {
    .trending-base-card {
      max-width: 24rem;

      &:nth-of-type(2) {
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }
    @media (max-width: 752px) {
      flex-direction: column;
      justify-content: center;

      .trending-base-card {
        max-width: 100%;
        margin: 2rem 0;

        &:nth-of-type(2) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

// PRE FOOTER SECTION
.pre-footer-section {
  background-image: linear-gradient(
      150deg,
      #2f3a44 25%,
      rgba(38, 46, 53, 0) 100%
    ),
    url('../../assets/images/home/blue-house.jpg');
  padding-top: 8vw;
  padding-bottom: 80px;
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: 100%;
  background-size: cover;

  .pre-footer-content {
    max-width: 45rem;
  }

  .pre-footer-button {
    a {
      background-color: $cornell;
      color: $white;
      border-radius: 5rem;
      transition: 0.3s;

      &:hover {
        text-decoration: none;
        background-color: darken($color: $cornell, $amount: 10);
      }
    }
  }

  @media (max-width: 752px) {
    .pre-footer-container {
      flex-direction: column;
      .pre-footer-button {
        margin: 0;
        margin-top: 3rem;
        margin-left: auto;
      }
    }
  }
}

.download-thank-you-section {
  padding-top: 16vh;
  padding-bottom: 16vh;
  background-color: #f2f2f2;

  .thank-you-div {
    max-width: 60rem;

    .mdc-dialog--open {
      z-index: 1000;
      .mdc-dialog__surface {
        max-width: 100%;

        .react-pdf__Page__canvas {
          width: 90% !important;
          height: auto !important;
        }
      }
    }
  }

  .download-thank-you-cta {
    background: $cornell;
    color: $white;
    border-radius: 5rem;

    &:hover {
      text-decoration: none;
      background-color: darken($color: $cornell, $amount: 10);
    }
  }
}

.download-thank-you__review-categories {
  h2 {
    color: $charcoal;
  }
}

@media (max-width: 752px) {
  .home-cta {
    width: 100%;
  }

  .download-thank-you-section {
    .download-thank-you-ctas {
      flex-direction: column;
      p {
        margin: 1rem auto;
      }
      .download-thank-you-cta {
        margin: auto;
      }
    }
  }
}

.brand-blue-bg {
  background-color: $brand-blue;
}

.dity-max-w {
  max-width: 1200px;
  margin: auto;
}

.dity-contest-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'title title title'
    'title2 title2 img'
    'details details img'
    'button . img';

  color: $white;

  .dity-header-title {
    grid-area: title;
    font-size: 6rem;
    line-height: 7rem;
    margin-bottom: 0;
  }

  .dity-header-title-2 {
    grid-area: title2;
    font-size: 6rem;
    line-height: 7rem;
    margin-top: 0;
  }

  .dity-header-image {
    max-width: 38rem;
    grid-area: img;
  }

  .dity-header-details {
    grid-area: details;
  }

  .dity-enter-button {
    grid-area: button;
    max-width: 12rem;
    height: fit-content;
    padding: 1.5rem;
    font-size: 1.25rem;
  }
}

.dity-sponsor-bg {
  background-color: #f2f5f7;
}

.dity-map-bg {
  background-color: $white;
  background-image: url('../../assets/images/dity/white-map-grid-box.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.dity-thanks {
  color: $darker-grey;
  white-space: nowrap;
}

.dity-entry-rules {
  max-width: 30rem;

  .dity-entry-rules__bullet {
    width: 5.5rem;
    line-height: 4rem;
    border-radius: 50%;
    text-align: center;
    font-size: 32px;
    border: 4px solid #000;
  }
}

.dity-entry__review-cards {
  filter: drop-shadow(13px 14px 13px #d5d5d5);
  -webkit-filter: drop-shadow(13px 14px 13px #d5d5d5);
}

.dity-grand-prize {
  max-width: 27rem;

  .dity-grand-prize__shyft-logo {
    max-width: 5rem;
  }

  .dity-grand-prize__moving-truck {
    max-width: 22rem;
  }
}

.dity-additional-prize {
  max-width: 22rem;

  .dity-additional-prize__card-amount {
    max-width: 17rem;
    @media (max-width: 640px) {
      max-width: 15rem;
    }
  }
}

.shyft-section {
  max-height: 30em;
  .shyft-description {
    max-width: 40rem;
  }

  .shyft-phones {
    max-height: 18rem;

    img {
      position: relative;
      bottom: 4rem;
      max-width: 23rem;
      filter: drop-shadow(13px 14px 13px #d5d5d5);
      -webkit-filter: drop-shadow(13px 14px 13px #d5d5d5);
    }
  }
}

.shyft-coupon-container {
  max-width: 65rem;

  .shyft-coupon {
    border: dashed 2px #0f30a5;
    border-radius: 5px;
    color: #0f30a5;

    h2 {
      font-size: 5rem;
    }
  }

  .cut-here-scissors {
    max-width: 2rem;
  }

  .shyft-coupon-description {
    max-width: 34rem;
  }
}

.mil-hd-logo {
  max-width: 6rem;
  &.w-full {
    width: 6rem;
  }
}

.home-depot-container {
  max-width: 72rem;

  .home-depot-box-img {
    max-width: 28rem;
  }

  .home-depot-description {
    max-width: 35rem;
  }

  ul {
    list-style-position: inside;
  }
}

.radisson-card-div {
  max-width: 20rem;

  .radisson-card {
    max-width: 20rem;
  }
}

.radisson-logo {
  max-width: 15rem;
}

.radisson-rewards {
  h2 {
    font-size: 2.5rem;
  }
}

.dity-social-links {
  max-width: 40rem;
  margin: auto;

  .dity-social-link {
    color: $white;
    font-size: 2.5rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;

    &.facebook-link {
      background-color: #1977f2;

      &:hover {
        filter: drop-shadow(3px 4px 4px lighten(#1977f2, 10%));
        -webkit-filter: drop-shadow(3px 4px 4px lighten(#1977f2, 10%));
      }
    }

    &.reddit-link {
      background-color: #ff3f19;

      &:hover {
        filter: drop-shadow(3px 4px 4px lighten(#ff3f19, 10%));
        -webkit-filter: drop-shadow(3px 4px 4px lighten(#ff3f19, 10%));
      }
    }

    &.twitter-link {
      background-color: #1ab7eb;

      &:hover {
        filter: drop-shadow(3px 4px 4px lighten(#1ab7eb, 10%));
        -webkit-filter: drop-shadow(3px 4px 4px lighten(#1ab7eb, 10%));
      }
    }

    &.instagram-link {
      background-image: url('../../assets/images/dity/instagram-logo-bg.jpg');
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      &:hover {
        filter: drop-shadow(3px 4px 4px lighten(#d02f89, 10%));
        -webkit-filter: drop-shadow(3px 4px 4px lighten(#d02f89, 10%));
      }
    }

    &.email-link {
      background-color: #0aa368;

      &:hover {
        filter: drop-shadow(3px 4px 4px lighten(#0aa368, 10%));
        -webkit-filter: drop-shadow(3px 4px 4px lighten(#0aa368, 10%));
      }
    }

    @media (max-width: 460px) {
      font-size: 2rem;
      width: 3.5rem;
      height: 3.5rem;
    }
  }
}

.dity-judge-container {
  max-width: 50rem;
}

.dity-judge-logos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 5rem 5rem;
  row-gap: 1rem;
  column-gap: 1rem;

  .dity-judge-logo {
    max-width: 14rem;

    &.mwm-logo {
      img {
        max-width: 60%;
      }
    }
  }
}

.dity-contest-rules {
  max-width: 54rem;
}

.dity-roman-list {
  list-style-type: lower-roman;
}

@media (max-width: 1024px) {
  .dity-contest-header {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'title title '
      'title2 img'
      'details img'
      'button img';

    .dity-header-title {
      font-size: 5rem;
      line-height: 6rem;
    }

    .dity-header-title-2 {
      font-size: 5rem;
      line-height: 6rem;
    }

    .dity-header-image {
      width: 100%;
      margin-top: auto;
    }
  }
}

@media (max-width: 850px) {
  .mobile-powered-by-title {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .shyft-section {
    max-height: fit-content;
    .dity-max-w {
      flex-direction: column;
      .shyft-description {
        padding-top: 0;
      }
      .shyft-phones {
        img {
          bottom: 2rem;
          max-width: 13rem;
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .dity-contest-header {
    .dity-header-title {
      font-size: 4rem;
      line-height: 5rem;
    }

    .dity-header-title-2 {
      font-size: 4rem;
      line-height: 5rem;
    }
  }

  .dity-entry__review-cards-container {
    display: none;
  }

  .dity-entry-rules {
    margin: auto;
  }

  .dity-sponsors {
    flex-direction: column;
    text-align: center;

    .shyft-logo {
      max-width: 20rem;
    }
  }

  .dity-judge-logos {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 5rem 5rem 5rem;
    max-width: 30rem;
    margin: auto;

    .dity-judge-logo {
      max-width: fit-content;

      &.mwm-logo {
        text-align: center;
        img {
          max-width: 50%;
        }
      }
    }
  }

  .shyft-coupon-section {
    padding-top: 0;
  }

  .shyft-coupon-container {
    margin-top: 0;

    .shyft-coupon {
      flex-direction: column;

      h2 {
        font-size: 4rem;
      }
    }

    .shyft-coupon-description {
      padding-top: 2rem;
      max-width: 28rem;
      margin: auto;
    }
  }

  .home-depot-container {
    flex-direction: column;

    .home-depot-box-img {
      margin: auto;
      padding-bottom: 1rem;
    }

    .home-depot-description {
      margin: auto;
      padding-bottom: 1rem;
    }

    .mobile-hd-logo {
      margin: auto;
      padding-bottom: 1rem;
    }
  }

  .radisson-section {
    flex-direction: column;

    .radisson-card-div {
      margin: auto;
      max-width: 100%;
    }

    .radisson-description-div {
      margin: auto;
      padding-top: 2rem;
    }
  }
}

@media (max-width: 650px) {
  .dity-contest-header {
    grid-template-areas:
      'img img '
      'title title'
      'title2 title2'
      'details details'
      'button button';

    .dity-header-title {
      font-size: 30px;
      line-height: 38px;
    }

    .dity-header-title-2 {
      font-size: 30px;
      line-height: 38px;
    }

    .dity-header-image {
      max-width: 25rem;
      margin: auto;
    }

    .dity-enter-button {
      max-width: 100%;
      width: 100%;
    }
  }
}

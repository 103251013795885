.concierge-request-thanks-header {
  background-color: $white;
  background-image: url('../../assets/images/concierge_requests/porch-background.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top;

  &.register-account {
    min-height: 100vh;
  }
}

.find-an-area-guide-section {
  background-color: $white;
  background-image: url('../../assets/images/concierge_requests/us-map-bg.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.blog-category-card {
  .blog-category-card__img {
    max-width: 20rem;
  }
}

@media (max-width: 1024px) {
  .blog-categories-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .blog-category-card {
      max-width: 30rem;
      flex-direction: column;

      @media (max-width: 752px) {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }

      .blog-category-card__img {
        margin-right: 0;
        max-width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

@media (max-width: 752px) {
  .concierge-request-thanks-header {
    padding-top: 0;
    padding-bottom: 0;
  }

  .find-an-area-guide-section {
    .area-guide-nav__card {
      max-width: 360px;
    }
  }
}

@use '@material/elevation';

.home-loans-header-section {
  background-position: left center;
  background-image: url('../../assets/images/home_loans/home-loan-header.jpg');
  position: relative;
  background-color: $white;
  background-size: cover;

  .home-loans-header__text {
    max-width: 24rem;
  }

  .home-loans-header__image {
    max-width: 24rem;
  }

  .home-loan-form {
    .ExternalMortgage {
      background: $white;
      border-radius: 1rem;
    }

    .wizard-cancel {
      display: none;
    }
  }
}

.home-loans-secondary-nav {
  @include elevation.elevation(4);

  position: sticky;
  top: 70px;
  z-index: 8;
  width: 100%;
  max-width: 100%;
}

@media (max-width: 1024px) {
  .home-loans-header-section {
    .home-loan-form {
      .ExternalMortgage {
        margin: 2rem auto;
        padding: 2rem;
      }
    }
  }

  .home-loans-secondary-nav {
    display: none;
  }
}

@media (max-width: 752px) {
  .home-loans-header-section {
    .home-loans-header__container {
      flex-direction: column;

      .home-loans-header__text {
        max-width: fit-content;
        padding: 0;
        padding-top: 2rem;
      }

      .home-loan-form {
        .ExternalMortgage {
          max-width: 100%;
        }
      }
    }
  }
}

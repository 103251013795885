.ui.form  .react-autosuggest__container.react-autosuggest__container--open {
  .react-autosuggest__suggestions-container--open {
    border: 1px solid #aaa;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: block;
    font-size: 16px;
  }

  .react-autosuggest__suggestions-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 10px 20px;
    }
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }
}

.ui.form  .react-autosuggest__container {
  .react-autosuggest__input {
    font-size: 16px;
    font-weight: 300;
    padding: 10px 20px;
  }
}

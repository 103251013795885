.sticky-header-drawer {
  z-index: 999;
  top: 0;

  .mobile-nav-drawer {
    a {
      font-size: 1.2rem;
    }

    li {
      font-size: 1.2rem;
    }

    .mdc-list-item {
      &.font-bold {
        font-weight: 700;
      }
    }
  }
}

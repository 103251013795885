.account-request-content.site-section {
  font-size: 16px;
  width: 90%;
  max-width: 1200px;
}

.account-request-grid {
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: minmax(auto, 60%) 420px;
  grid-gap: 20px;
  justify-content: space-around;
  justify-items: center;
  min-height: 700px;

  &.form-page {
    grid-template-rows: 281px max-content;
    grid-template-areas:
      'account-request-image account-request-sidebar'
      'account-request-info account-request-sidebar';
  }

  &.thank-you-page {
    grid-template-rows: min-content max-content;
    grid-template-areas:
      'account-request-info account-request-sidebar'
      'account-request-image account-request-sidebar';
  }

  @media only screen and (max-width: 1024px) {
    &.form-page {
      grid-template-rows: min-content;
      grid-template-columns: auto;
      grid-template-areas:
        'account-request-image'
        'account-request-sidebar'
        'account-request-info';
    }

    &.thank-you-page {
      grid-template-rows: min-content;
      grid-template-columns: auto;
      grid-template-areas:
        'account-request-info'
        'account-request-sidebar'
        'account-request-image';
    }
  }
}

.account-request-info {
  font-size: 16px;
  grid-area: account-request-info;
}

.account-request-sidebar {
  grid-area: account-request-sidebar;

  .account-request-sidebar-button {
    background-color: $chambray;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    display: block;
    font-size: 24px;
    margin: auto;
    padding: 15px 40px;

    &:hover,
    &:active {
      background-color: darken($chambray, 10%);
    }
  }
}

.account-request-image {
  @include profile-image;
  height: auto;
  grid-area: account-request-image;
  max-width: 100%;
}

.did-you-know-list{
  margin-left: 10px;

  li {
    margin-bottom: 5px;
  }
}

.working-with-us {
  padding: 0 10px;

  .work-with-us-item {
    margin: 0 0 15px 10px;
  }

  .work-with-us-sublist {
    list-style-type: disc;
    margin-left: 15px;

    li {
      margin-bottom: 5px;
    }
  }
}

div.oauth-button {
  font-size: 1.25rem;
  height: 50px;
  text-align: center;
  width: 100%;

  .facebook {
    align-items: center;
    background-color: $white;
    border-radius: 5px;
    border: solid 1px $default;
    color: $default;
    display: flex;
    font-size: 18px;
    height: 100%;
    line-height: 36px;

    &:hover {
      text-decoration: none;
    }

    .button-text {
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      padding-left: 0.5rem;
    }
  }

  .fa-facebook {
    color: #4267b2;
    height: auto;
    width: 2rem;
  }

  .google-icon {
    width: 2rem;
  }
}

.login-button {
  border-radius: 5px;
  border: solid 1px $darker-grey;
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  font-size: 18px;
  color: $charcoal;

  &:hover,
  &:focus,
  &:visited {
    text-decoration: none;
    color: $charcoal;
  }

  &:hover {
    cursor: pointer;
  }

  .icon {
    max-width: 2rem;

    svg {
      font-size: 2rem;

      &.fa-envelope {
        color: $lighter-blue;
      }

      &.fa-facebook {
        color: #4267b2;
      }
    }
  }
}

.or-separator {
  align-items: center;
  color: $default;
  display: flex;
  flex: 0 1 100%;
  font-size: 1.25rem;
  margin: 15px 0;
  width: 100%;

  &::before,
  &::after {
    background: rgba(#8c8b8b, 0.5);
    content: '';
    flex-grow: 1;
    font-size: 0;
    height: 1px;
    line-height: 0;
    margin: 0 8px;
  }
}

.oauth-facebook__vector-icon {
  height: auto;
  margin-right: 15px;
  width: 29px;
}

.signin-or-signup-button {
  background-color: $red;
  border: 1px solid #fff;
  border-radius: 5px;
  color: $white;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 20px;
  padding: 10px;
  width: 100%;

  &:hover {
    background-color: darken($red, 10%);
  }
}

.pcs-field {
  margin-bottom: 20px;

  .react-datepicker-wrapper {
    margin: auto;
    max-width: 28rem;
    width: 100%;

    .react-datepicker__input-container {
      max-width: 100%;
    }
  }

  &.moving-questions {
    .react-datepicker-wrapper {
      max-width: 100%;
      input {
        border: solid #c4c4c4 1px;
      }
    }
    .react-datepicker-popper {
      z-index: 10;
    }
  }
}

.pcs-field__label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

.pcs-field__input {
  background: $white;
  border: 1px solid rgba($mystic, 0.25);
  border-radius: 5px;
  display: block;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 12.5px;

  &.pcs-field__input--full-width {
    width: 100%;
  }
}

.money-field {
  display: inline-flex;
  position: relative;
}

.money-field__input {
  padding-left: 30px;
  width: 100%;
}

.money-field__icon {
  height: 100%;
  left: 1px;
  position: absolute;
  width: 30px;
}

.money-field--dark-filled {
  border-radius: 5px;

  .money-field__input {
    border-color: $middle-light-blue;
    border-width: 2px;
    padding-left: 55px;
  }

  .money-field__icon {
    background-color: $middle-light-blue;
    border-bottom-left-radius: inherit;
    border-top-left-radius: inherit;
    width: 40px;

    svg {
      fill: $white;
    }
  }
}

.select-field {
  display: block;
  position: relative;
}

.select-field__input {
  border: 1px solid $mystic;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  display: block;

  &:hover {
    border-color: darken($mystic, 10%);
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    outline: 0;
  }
}

.select-field__icon {
  bottom: 0;
  color: $outer-space;
  position: absolute;
  right: 0;
  top: 0;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.password-field {
  display: inline-flex;
  position: relative;
}

.password-field__input {
  padding-right: 30px;
  width: 100%;
}

.password-field__icon {
  cursor: pointer;
  height: 100%;
  position: absolute;
  right: 1px;
  width: 30px;
}

.radio-input {
  cursor: pointer;
  font-size: 16px;

  .radio-input__custom-toggle {
    align-items: center;
    border: 1px solid #969696;
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    height: 15px;
    justify-content: center;
    margin-right: 5px;
    position: relative;
    vertical-align: middle;
    width: 15px;
  }

  input:checked ~ .radio-input__custom-toggle::after {
    background: #1785ff;
    border-radius: 100%;
    content: '';
    height: inherit;
    transform: scale(0.5);
    width: inherit;
  }

  input {
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px;
  }
}

.concierge-form {
  .pcs-field__radios {
    label {
      display: flex;
      align-items: center;
    }
  }
}

.radio-input {
  .radio-input__custom-toggle {
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }

  input:checked ~ .radio-input__custom-toggle::after {
    background: $brand-blue;
    height: 100%;
    width: 100%;
    transform: scale(0.8);
  }

  input:checked ~ .radio-input__custom-toggle {
    border: $brand-blue solid 2px;
  }
}

.pcs-field__radios {
  display: flex;
}

.rebate-estimator {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.estimator-fields {
  .money-field {
    margin-bottom: 20px;
  }
}

.rebate-estimate {
  background-color: $middle-light-blue;
  border-radius: 5px;
  color: $white;
  font-size: 16px;
  margin-bottom: 15px;
  max-width: 250px;
  padding: 10px;
  text-align: center;

  .rebate-estimate__sub-text {
    display: block;
  }
}

.realtor-area {
  justify-content: space-between;

  .realtor-area_state-select {
    margin-right: 11em;
    max-width: 10em;
  }

  .realtor-area_area-input {
    max-width: 20em;
  }
}

.concierge-form {
  background-color: $white;
  color: $default;

  input {
    border: solid 1px $default;
  }

  label {
    font-weight: bold;
  }

  select {
    border: solid 1px $default;
    height: 45px;
  }

  .state-half-width {
    width: 13rem;
  }

  fieldset {
    border: none;
    padding: none;
  }

  .concierge-radio {
    max-width: 20rem;
    &.prequalified {
      max-width: 10rem;
    }
    input {
      margin-right: 0.5rem;
    }
  }
}

.listInputItems {
  li {
    cursor: pointer;

    p {
      color: $default;
    }
  }
}

.error-message {
  font-size: 12px;
  color: #d32f2f;
}

.admins-realtors-zipcodes-input {
  max-width: 50rem;

  .MuiInputBase-adornedEnd {
    padding-right: 0;
  }
}

.admins-realtors-zipcodes__remove {
  border: 2px solid $thunderbird;
  border-radius: 50%;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  font-size: 1rem;
  line-height: 0.7rem;
}

@media (max-width: 1024px) {
  .concierge-form,
  .lender-form {
    &.mdc-elevation--z4 {
      box-shadow: none;
      .wizard-fields.p-8 {
        padding: 0;
      }
    }
  }
  .ExternalMortgage {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }

  .home-loan-form {
    .concierge-form,
    .lender-form {
      margin: 0;
      margin-top: 2rem;
      &.mdc-elevation--z4 {
        .wizard-fields.p-8 {
          padding: 2rem;
        }
      }
    }
  }
}

@media (max-width: 530px) {
  body.realtorscontroller.realtors.index {
    .concierge-form {
      .looking-to__img-buttons {
        flex-direction: column;

        .looking-to-tab {
          flex-direction: row;
          max-width: 100%;
          max-height: 3rem;
          justify-content: flex-start;

          img {
            max-height: 2rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.buy-sell-concierge-form {
  max-width: 40rem;
  background: $white;
  border-radius: 1rem;
}

@media (max-width: 485px) {
  .concierge-form {
    .half-width {
      max-width: 9.5rem;
    }

    .state-half-width {
      max-width: 9.5rem;
    }
  }
}

@media (max-width: 375px) {
  div.oauth-button {
    .facebook {
      .px-6 {
        padding-right: 0.5rem;
      }
      .mx-6 {
        margin-right: 0.5rem;
      }
    }
  }
}

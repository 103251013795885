.crisp-image {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.imagecontainer-img {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 400ms transform;
  object-fit: cover;
  font-family: 'object-fit: cover';
  z-index: -1;

  &.brightness-50 {
    filter: brightness(50%);
  }
}

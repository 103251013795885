.contact-us-header {
  background-image: linear-gradient(
      150deg,
      #2f3a44 25%,
      rgba(38, 46, 53, 0) 100%
    ),
    url('../../assets/images/contact_us/contact-us-header-image.jpg');
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: 100%;
  background-size: cover;

  padding-top: 126px;
  padding-bottom: 126px;
}

.contact-us-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 4rem;
  grid-column-gap: 4rem;

  .contact-us-item {
    min-height: 18rem;
    .contact-us-icon {
      font-size: 2.5rem;
    }

    .contact-us-button {
      max-width: 25rem;
    }
  }

  @media (max-width: 780px) {
    grid-template-columns: 1fr;
    max-width: 40rem;
    margin: auto;
    .contact-us-item {
      min-height: fit-content;
      .contact-us-button {
        margin-top: 1rem;
        max-width: 100%;
      }
    }
  }
}

@use '@material/button';

.mvc-awards-grid {
  background-color: rgba($mvc-blue, 0.9);
  color: $white;
  display: grid;
  grid:
    [row1-start] 'header header header' 75px [row1-end]
    [row2-start] 'subheader subheader subheader' minmax(50px, min-content)
    [row2-end]
    [row3-start] 'main main main' auto [row3-end]
    [row4-start] 'sponsors sponsors sponsors' auto [row4-end]
    [row5-start] 'post-content post-content post-content' auto [row5-end]
    / auto minmax(300px, 1025px) auto;

  &.mvc-categories-layout {
    .mvc-content {
      display: initial;
    }
  }

  a {
    color: $mvc-yellow;

    &:hover,
    &:focus {
      color: rgba($mvc-yellow, 0.8);
      text-decoration: underline;
    }

    &:visited {
      color: rgba($mvc-yellow, 0.8);
      text-decoration: none;
    }
  }

  .mdc-menu-surface--anchor {
    cursor: pointer;

    & > .mdc-menu-surface .mdc-list-item {
      color: inherit;
    }
  }
}

.mvc-countdown-bar {
  align-items: center;
  background-color: $mystic;
  display: flex;
  grid-area: subheader;
  justify-content: flex-end;
  padding: 0 5%;

  p {
    color: $default;
    margin: 0;
  }

  .mvc-countdown {
    margin: 0 25px;

    .countdown-section {
      background-color: initial;
      box-shadow: none;
      padding: 5px;
      position: relative;

      small {
        display: none;
      }

      &:not(:last-child)::after {
        content: ':';
        position: absolute;
        right: -7px;
      }
    }
  }

  .mvc-countdown-cta.ui.button {
    background-color: $mvc-yellow;
    color: $white;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background-color: rgba($mvc-yellow, 0.8);
      color: $white;
    }
  }

  @media (max-width: 725px) {
    flex-direction: column;
    padding: 10px;
  }
}

.mvc-sponsors-sections {
  background-color: #f1f1ef;
  color: $default;
  grid-area: sponsors;
  padding: 50px 10px;
  width: 100%;

  @media (max-width: 350px) {
    padding: 50px 0;
  }
}

.sponsors-list {
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
  margin-top: 25px;
  max-width: 825px;
  width: 100%;

  .sponsors-list-item {
    margin: 0 10px 25px 0;
    max-width: 100%;
    width: 200px;

    &.sponsors-list-item--large {
      width: 300px;
    }

    img {
      height: auto;
      width: 100%;
    }
  }
}

.mvc-sponsors-section {
  margin: auto;
  max-width: 1025px;
  text-align: center;
  width: 90%;

  .mvc-sponsor-text {
    font-size: 16px;
    line-height: 1.4;
    margin: auto;
    max-width: 900px;
    text-align: center;
  }

  &:not(:last-child) {
    margin-bottom: 60px;
  }

  h3 {
    font-size: 24px;
    text-transform: uppercase;

    &::before,
    &::after {
      background-color: rgba($mvc-blue, 0.6);
      content: '';
      display: inline-block;
      height: 3px;
      margin: 8px 0;
      width: 300px;
    }

    &::before {
      margin-right: 15px;
    }

    &::after {
      margin-left: 15px;
    }
  }

  @media (max-width: 1140px) {
    h3 {
      border-bottom: 3px solid rgba($mvc-blue, 0.6);
      border-top: 3px solid rgba($mvc-blue, 0.6);

      &::before,
      &::after {
        display: none;
      }
    }
  }
}

.mvc-header-container {
  grid-area: header;

  .nav-menu-button {
    margin: 0 5%;
    width: 90%;

    &.sign-up-button {
      margin-top: 15px;
    }

    &.log-in-button {
      @include button.outline-color($white);
      --mdc-theme-primary: #fff;
    }

    &:hover,
    &:active {
      color: $white;
      text-decoration: none;
    }
  }
}

section.site-section.mvc-content {
  grid-area: main;
  max-width: 1025px;
  width: 90%;
}

.mvc-content.mvc-homepage {
  display: grid;
  grid-template-areas:
    'headline headline headline verify verify verify'
    'voting-categories voting-categories voting-categories voting-categories voting-categories voting-categories'
    'relocation relocation organization organization influence influence';
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 350px min-content 300px;
  justify-content: stretch;
  min-height: 625px;
  position: relative;

  @media (max-width: 1024px) {
    grid-template-areas:
      'headline'
      'headline'
      'verify'
      'verify'
      'voting-categories'
      'relocation'
      'organization'
      'influence';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    row-gap: 10px;
  }
}

.mvc-voting-category-headline {
  border-bottom: 1px solid rgba($white, 0.6);
  border-top: 1px solid rgba($white, 0.6);
  color: $mvc-yellow;
  grid-area: voting-categories;
  margin: 35px 0 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.mvc-content.mvc-terms-of-service {
  font-size: 16px;
}

section.mvc-content.mvc-default-layout {
  margin-top: 0;
  max-width: 900px;
  min-height: 890px;
  padding: 0 0 50px;

  h1 {
    text-align: center;
  }
}

#wrap > section.mvc-content.mvc-categories-page {
  margin-top: 0;
  min-height: 890px;
  padding-bottom: 50px;
}

section.mvc-content.mvc-mission-statement {
  p {
    font-size: 18px;
    line-height: 1.5;
  }
}

section.mvc-content.mvc-frequently-asked-questions {
  .mvc-faqs-list {
    line-height: 1.5;

    .mvc-faqs-term {
      font-size: 22px;
      margin-bottom: 20px;
    }

    .mvc-faqs-definition {
      font-size: 18px;
      margin-left: 20px;
      margin-bottom: 20px;

      strong {
        font-size: 19px;
      }
    }

    ul {
      list-style-position: inside;
      margin-bottom: 20px;
      padding-left: 20px;
    }
  }
}

section.mvc-content.mvc-privacy-policy,
section.mvc-content.mvc-gdpr,
section.mvc-content.mvc-award-rules {
  ul {
    list-style-position: inside;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  strong {
    font-size: 19px;
  }

  p,
  li {
    font-size: 18px;
    line-height: 1.5;
  }
}

section.mvc-content.mvc-contact-us {
  font-size: 18px;
  text-align: center;
}

.mvc-awards-medal {
  grid-area: headline;
  left: -60px;
  position: absolute;
  top: 40px;
}

.mvc-headline {
  grid-area: headline;
  max-width: 500px;

  p {
    margin: 0;
  }

  .mvc-main-cta {
    font-size: 50px;
    margin: 5px 0;
    text-transform: uppercase;
  }

  .mvc-small-tagline {
    text-transform: uppercase;
  }

  .mvc-second-tagline {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .mvc-last-tagline {
    font-size: 16px;
    font-style: italic;
    line-height: 1.6;

    a {
      text-decoration: underline;
    }
  }
}

.mvc-get-verified {
  @include flexbox;
  @include flex-direction(column);
  @include align-items(center);
  background-color: $white;
  border-radius: 10px;
  grid-area: verify;
  max-width: 500px;
  padding: 40px 0;
  text-align: center;

  h2 {
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  .mvc-verify-button {
    background-color: $mvc-yellow;
    border-radius: 7px;
    color: $white;
    filter: drop-shadow(16px 16px 10px rgba(0, 0, 0, 0.3));
    font-size: 25px;
    padding: 10px 50px;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background-color: lighten($mvc-yellow, 10%);
      color: $white;
      text-decoration: none;
    }
  }

  .verify-button-cta::after {
    border-bottom: 1px solid $white;
    content: '';
    width: 50px;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    .mvc-verify-button {
      font-size: 22px;
      padding: 10px 25px;
    }
  }
}

.mvc-category-section {
  .mvc-category-title {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(flex-start);
    @include align-items(center);
    height: 50px;
    margin-bottom: 15px;
    padding: 0 20px;

    h3 {
      margin: 0 0 0 15px;
      text-transform: uppercase;
    }
  }

  .mvc-categories-list {
    list-style: none;
    padding: 0 20px;

    li:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &.relocations {
    grid-area: relocation;
  }

  &.support-organizations {
    grid-area: organization;
  }

  &.military-influencers {
    grid-area: influence;
  }

  &:not(.military-influencers) {
    border-right: 1px solid rgba($white, 0.6);
  }

  @media (max-width: 1024px) {
    max-width: 500px;
    width: 100%;

    &:not(.military-influencers) {
      border-right: none;
    }
  }
}

.mvc-post-content {
  grid-area: post-content;
}

.mvc-home-stats {
  @include justify-content(space-around);
  margin: auto;
  max-width: 1025px;
  padding: 50px 0;
  text-align: center;
  width: 90%;

  .mvc-stat {
    font-size: 24px;
  }
}

.mvc-white-text {
  &.primary {
    color: $white;
  }

  &.secondary {
    color: rgba($white, 0.7);
  }
}

.mvc-yellow-text {
  &.primary {
    color: $mvc-yellow;
  }

  &.secondary {
    color: rgba($mvc-yellow, 0.8);
  }
}

.mvc-blue-background {
  background: $mvc-blue;
}

.mvc-dark-blue-background {
  background: $mvc-darker-blue;
}

.mvc-header {
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(space-between);
  @include align-items(center);
  border-bottom: 1px solid rgba($white, 0.4);
  height: 75px;

  .mvc-menu-icon {
    cursor: pointer;
    display: none;
    fill: #fff;
    height: auto;
    width: 25px;

    @media (max-width: 1024px) {
      display: unset;
    }
  }

  .featured-sponsor {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(space-between);
    color: #fff;
    height: 100%;
    width: 225px;
    text-align: center;

    p {
      @include flex(1);
      background-color: rgba($white, 0.2);
      font-size: 12px;
      letter-spacing: 3px;
      margin: 0;
      text-transform: uppercase;
    }

    .sponsor-logo {
      display: block;
      height: 60px;

      img {
        height: 100%;
        width: auto;
      }
    }

    @media (max-width: 1140px) {
      width: 200px;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    padding: 0 20px;
  }

  @media (max-width: 428px) {
    padding: 0 10px;
  }
}

.mvc-action-menus {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 70px;

  &.without-members-menu {
    display: none;

    @media (max-width: 1024px) {
      display: flex;
      width: auto;
    }
  }
}

.mvc-nav {
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(flex-end);
  @include align-items(center);
  @include flex(1);
  height: 100%;
  margin: 0 30px;

  a {
    letter-spacing: 1.5px;
    text-transform: uppercase;

    &.active {
      color: darken($mvc-yellow, 10%);
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .mvc-login {
    border: 1px solid $white;
    border-radius: 2.5px;
    color: #fff;
    cursor: pointer;
    line-height: 1rem;
    padding: 10px 15px;

    &:first-of-type {
      margin-right: 5px;
    }

    &:hover,
    &:focus {
      border: 1px solid lighten($mvc-yellow, 10%);
      color: lighten($mvc-yellow, 10%);
    }
  }

  .inline-list {
    height: 100%;

    li {
      @include flexbox;
      @include flex-direction(row);
      @include align-items(center);
    }

    .mvc-nav-link {
      color: rgba($white, 0.7);
      margin-right: 10px;

      &:hover,
      &:focus {
        color: $white;
      }

      &.active {
        color: $mvc-yellow;

        &:hover,
        &:focus {
          color: lighten($mvc-yellow, 10%);
        }
      }
    }

    li:not(:last-child)::after {
      color: rgba($white, 0.7);
      content: '-';
      margin-right: 10px;
    }
  }

  @media (max-width: 1140px) {
    margin: 0 5px;

    .inline-list {
      a {
        margin-right: 5px;
      }

      li:not(:last-child)::after {
        margin-right: 5px;
      }
    }
  }
}

.mvc-header-logo {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  height: 100%;
  margin-left: 20px;

  img {
    width: 236px;
  }

  @media (max-width: 1140px) {
    margin-left: 5px;
  }
}

.mvc-footer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 25px 0;

  .footer-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    > p {
      margin-bottom: 0;
    }
  }
}

.mvc-footer-nav {
  margin-bottom: 20px;
  max-width: 500px;
  width: 100%;

  .mvc-footer-nav-list {
    justify-content: space-around;
    font-size: 16px;
    width: 100%;

    a {
      color: $mvc-yellow;

      &:hover,
      &:focus {
        color: rgba($mvc-yellow, 0.8);
      }
    }
  }

  @media (max-width: 480px) {
    .mvc-footer-nav-list {
      font-size: 14px;
    }
  }
}

.mvc-social-links {
  margin-bottom: 20px;
  max-width: 200px;
  width: 100%;

  .inline-list {
    justify-content: space-around;
    width: 100%;
  }

  .mvc-social-link {
    a {
      border: 0;
      color: rgba($white, 0.7);
      font-size: 20px;

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  .mvc-social-link:not(:last-child) {
    margin-right: 10px;
  }
}

.mvc-countdown {
  margin: 20px 0 10px;

  .countdown-section {
    @include inline-flex;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    background-color: $white;
    border-radius: 2.5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: $default;
    padding: 10px;

    .countdown-number {
      font-size: 20px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    small {
      color: $mvc-blue;
      text-transform: uppercase;
    }
  }
}

.mvc-login-signup-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  margin-bottom: 20px;
  max-width: 700px;
  padding: 0;

  .mvc-login-signup-divider {
    flex: 0 0 100%;
    margin: 0;
  }

  .mvc-login-signup-header {
    flex: 0 0 100%;
    padding-top: 10px;

    h1 {
      color: rgba($mvc-blue, 0.9);
      font-size: 35px;
    }
  }

  .mvc-login-form {
    flex: 0 0 70%;
    margin: 30px 0;
    max-width: 100%;
    padding: 0 10px;
  }

  .mvc-signup-get-verified-button {
    align-items: center;
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    justify-content: center;
    margin: 30px 0;
    max-width: 500px;

    .mvc-verify-button {
      background-color: $mvc-yellow;
      border-radius: 7px;
      color: $white;
      filter: drop-shadow(16px 16px 10px rgba(0, 0, 0, 0.3));
      font-size: 25px;
      padding: 10px 50px;
      text-align: center;
      text-transform: uppercase;

      &:hover,
      &:focus {
        background-color: lighten($mvc-yellow, 10%);
        color: $white;
        text-decoration: none;
      }
    }
  }

  .login-or-signup-alternate {
    a {
      color: $mvc-blue;

      &:hover,
      &:focus {
        color: rgba($mvc-blue, 0.8);
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .mvc-login-form {
      flex: 0 0 90%;
    }
  }

  @media (max-width: 480px) {
    .mvc-signup-get-verified-button {
      .mvc-verify-button {
        font-size: 22px;
        padding: 10px 25px;
      }
    }
  }
}

.mvc-categories {
  .category-tabs {
    border-bottom: 1px solid rgba($white, 0.6);
    display: flex;
    margin-bottom: 50px;

    .category-tab {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex: 30%;
      flex-direction: row;
      justify-content: center;
      padding: 15px 10px;

      &:not(:last-child) {
        border-right: 1px solid rgba($white, 0.6);
      }

      &.active {
        background-color: rgba($mvc-yellow, 0.4);
      }

      h2 {
        font-size: 20px;
        margin: 0 0 0 15px;
        text-transform: uppercase;
      }
    }
  }

  .mvc-gradeable-resource {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 50px;
    max-width: 300px;
    min-height: 350px;

    .resource-title {
      align-items: center;
      display: flex;
      justify-content: center;
      letter-spacing: 1.5px;
      margin: 5px 0 10px;
      min-height: 46px;
      text-align: center;
    }

    .resource-description {
      flex-grow: 1;
      line-height: 1.6;
      text-align: center;
    }

    .resource-image {
      height: auto;
      max-width: 100%;
    }
  }

  .mvc-category-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    &.support-organizations {
      .mvc-gradeable-resource {
        max-width: 375px;

        .resource-image {
          margin-bottom: 15px;
        }
      }
    }
  }

  .resource-vote-button {
    border: 1px solid $mvc-yellow;
    color: $mvc-yellow;
    cursor: pointer;
    letter-spacing: 1.6px;
    padding: 10px;

    &:hover,
    &:focus {
      border: 1px solid rgba($mvc-yellow, 0.8);
      color: rgba($mvc-yellow, 0.8);
      text-decoration: none;
    }
  }
}

.voting-ended {
  padding: 20px;
}

.voting-ended__details {
  font-size: 18px;
  margin: auto;
  max-width: 800px;
}

#mvc-voting-ended-modal {
  font-size: 16px;

  .header {
    text-align: center;
  }

  .ui.button {
    display: block;
    margin: auto;
    max-width: 225px;
  }
}

#mvc-pre-vote-modal {
  text-align: center;
}

@media (min-width: 1025px) {
  .mvc-headline {
    padding: 0 10px 0 40px;
  }
}

@media (max-width: 1024px) {
  .mvc-categories > .category-tabs {
    border-bottom: none;
    border-left: 1px solid rgba($white, 0.6);
    border-right: 1px solid rgba($white, 0.6);
    flex-direction: column;

    .category-tab {
      border-bottom: 1px solid rgba($white, 0.6);
    }
  }

  .mvc-awards-medal {
    display: none;
  }

  .mvc-mobile-hide {
    display: none;
  }
}

.testimonial-container {
  max-width: 80rem;

  .review-date {
    color: $outer-space;
  }

  .testimonial-caret {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .testimonial-container {
    cursor: pointer;

    .testimonial-review__collapsed {
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .testimonial-caret {
      display: block;
      font-size: 2rem;
    }
  }

  .testimonial-cta-div {
    max-width: 500px;
    margin: auto;

    &.flex {
      flex-wrap: wrap;

      label {
        margin-right: 0;
        width: 100%;
      }

      a {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
}

.dl-inline {
  dt,
  dd {
    display: inline-block;
  }

  &__item {
    margin-bottom: 0.5rem;
  }
}

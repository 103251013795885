$site_width: 1020px;
$flex-width: 320px;

$grade-a-green: #56c234;
$grade-b-blue: #3a539b;
$grade-c-yellow: #f0db2d;
$grade-d-orange: #f09b2d; // #E8720A
$grade-f-red: #e0172d;

$default: rgba(0, 0, 0, 0.8);
$white: #fff;
$black: #000;
$grey: #7f7f7f;
$muted-grey: #d4d4d4;
$darker-grey: #898989;
$charcoal: #333;
$darker-blue: rgb(13, 43, 79);
$normal-blue: #1d6398;
$lighter-blue: rgb(53, 121, 180);
$subnav-blue: #387cb0;
$new-blue: #31446b;
$brand-blue: #2c3a89;
$red: #e0172d;
$lighter-red: lighten(#e0172d, 20%);
$chambray: #3a539b;
$shamrock: #2ecc71;
$thunderbird: #d91e18;
$cornell: #bb1d2a;
$summer-sky: #1e8bc3;
$silver-sand: #bdc3c7;
$saffron: #f4d03f;
$casablanca: #ecad45;
$mystic: #e8ecf1;
$outer-space: #2e3131;
$ming: #336e7b;
$alice-blue: #e4f1fe;

$middle-dark-blue: #28337a;
$middle-light-blue: #2d3a8a;

// jason has #f1cb2f;
$mvc-yellow: #fc0;
$mvc-blue: #002164;
$mvc-darker-blue: #021235;

$gradient-start: #f1f1f1;
$gradient-end: #e0e0e0;

$brand-red: #ba1d2b;

.area-guide-nav__container {
  max-width: 80rem;
}

.area-guide-nav__card {
  max-width: 180px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 10px -4px #707070;
  box-shadow: 0px 5px 10px -4px #707070;
  text-decoration: none;

  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:hover {
    -webkit-box-shadow: 0px 5px 10px 1px #707070;
    box-shadow: 0px 5px 10px 1px #707070;
    text-decoration: none;
  }
}

@media only screen and (max-width: 442px) {
  .area-guide-nav__card {
    max-width: 360px;
  }
}

@use '@material/elevation';

.real-estate-rewards__header-section {
  background-image: linear-gradient(
      150deg,
      #2f3a44 25%,
      rgba(38, 46, 53, 0) 100%
    ),
    url('../../assets/images/home/realtor-and-couple.jpg');
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: 100%;
  background-size: cover;
  padding-top: 10vh;

  .real-estate-rewards__header-text {
    max-width: 42rem;
  }

  &.real-estate-rewards__index-header {
    background-image: url('../../assets/images/landing_pages/rer-family-header-desktop.jpg');
    min-height: 100vh;
    .real-estate-rewards__wizard {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 60rem;
    }

    @media (max-width: 1024px) {
      background-image: url('../../assets/images/landing_pages/rer-family-header-mobile.jpg');
      .real-estate-rewards__wizard {
        margin-top: 0;
      }
    }
  }
}

.real-estate-rewards__wizard {
  max-width: 36rem;

  .rebates-wizard {
    border-radius: 1rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.rer-page__wizard {
  text-align: center;
  h1 {
    color: $cornell;
  }
}

.rer-page__hide {
  display: none;
  &.ui.button {
    display: none;
  }
}

.rer-grey-bg {
  background-color: #f2f2f2;
}

.client-stats-section {
  .my-8 {
    max-width: 22rem;

    @media (max-width: 480px) {
      margin-top: 1rem;
      margin-bottom: 1rem;

      &.home-loan-benefit:last-of-type {
        margin-bottom: 2rem;
      }
    }

    .home-loan-benefit_image {
      max-width: 10rem;
    }
  }
  .client-stat {
    font-size: 5rem;
    line-height: 3rem;

    @media (max-width: 480px) {
      font-size: 3.5rem;
      line-height: 2rem;
    }
  }
}

.external-lead-wizard-content {
  max-width: 40rem;
  h1 {
    margin-top: 0;
  }

  .external-lead-wizard-content__back {
    cursor: pointer;
    svg {
      height: 2rem;
    }

    &:hover {
      svg {
        filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.4));
      }
    }
  }

  .external-lead-wizard-content__fields {
    max-width: 30rem;

    .MuiOutlinedInput-input,
    .MuiInputBase-adornedStart {
      background-color: $white;
    }
  }
}

.easier-move-text {
  max-width: 664px;
}

.cash-back-section {
  .cash-back-section__steps {
    max-width: 28rem;

    .cash-back-section__step-icon {
      font-size: 5rem;
    }
  }

  .cash-back-section__rer-body {
    max-width: 30rem;
  }

  .rebate-calc-container {
    max-width: 560px;

    .cash-back-section__image-mobile {
      display: none;
    }
  }
}

.our-affiliates-section__text {
  max-width: 48rem;
}

.our-affiliates-section__house-image {
  background-image: url('../../assets/images/landing_pages/our-affiliates-house.jpg');
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  float: left;
  position: relative;
  z-index: 2;
  min-height: 1px;

  .our-affiliates-section__mask {
    background-image: url('../../assets/images/landing_pages/our-affiliates-mask.svg');
    transform: scale(-1, 1);
    background-size: calc(100% + 2px) calc(100% + 2px);
    background-repeat: no-repeat;
    background-position: 50%;
    overflow: hidden;
    bottom: 0;
    left: -1px;
    position: absolute;
    right: 0;
    top: -1px;
  }
}

.our-affiliates-section__cta-nav {
  position: sticky;
  @include elevation.elevation(4);
  top: 70px;
  z-index: 5;
}

.recent-reviews-section__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-areas:
    'review1 review1 review1'
    'review2 review3 review3'
    'review4 review4 review4';

  .recent-reviews-section__review-1 {
    grid-area: review1;

    .recent-reviews-section__review-1-image {
      max-width: 38rem;
    }

    .recent-reviews-section__review-1-text {
      position: relative;
      left: -100px;
      bottom: 15px;
      margin-right: -100px;
    }
  }

  .recent-reviews-section__review-2 {
    grid-area: review2;
    max-width: 28rem;

    .recent-reviews-section__review-2-text {
      max-width: 85%;
      position: relative;
      bottom: 80px;
    }
  }

  .recent-reviews-section__review-3 {
    grid-area: review3;

    .recent-reviews-section__review-3-text {
      max-width: 90%;
      position: relative;
      bottom: 80px;
      margin-bottom: -80px;
    }
  }

  .recent-reviews-section__review-4 {
    grid-area: review4;

    .recent-reviews-section__review-4-image {
      max-width: 40rem;
    }

    .recent-reviews-section__review-4-text {
      position: relative;
      left: -100px;
      bottom: 60px;
      margin-right: -100px;
    }
  }
}

@media (max-width: 1024px) {
  .real-estate-rewards__header-container {
    flex-direction: column;
    .real-estate-rewards__header-text {
      max-width: 100%;
    }

    .real-estate-rewards__wizard {
      max-width: 100%;
      margin-left: 0;
      margin-top: 2rem;
    }
  }

  .easier-move-section {
    flex-direction: column;
    padding: 0;

    .easier-move-section__image {
      margin: auto;
    }
  }

  .cash-back-section__header {
    padding-bottom: 0;
  }

  .cash-back-section__container {
    flex-direction: column;

    .cash-back-section__steps {
      max-width: 100%;
      margin: 0;

      .cash-back-section__step {
        display: flex;
        align-items: flex-start;
        padding-left: 2rem;
        margin-top: 2rem;
        margin-bottom: 2rem;

        .cornell-text {
          max-width: 3rem;
          margin-right: 2rem;

          .cash-back-section__step-icon {
            font-size: 3rem;
          }
        }
      }
    }

    .cash-back-section__rer-body {
      max-width: 100%;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .rebate-calc-container {
      margin: auto;
      max-width: 100%;
      width: 100%;

      .cash-back-section__image-desktop {
        display: none;
      }

      .cash-back-section__image-mobile {
        display: block;
      }
    }
  }

  .our-affiliates-section__text {
    margin: auto;
    max-width: 100%;

    h2 {
      padding-top: 0;
    }
  }

  .our-affiliates-section__house-image {
    display: none;
  }

  .our-affiliates-section__cta-nav {
    .our-affiliates-section__cta-container {
      flex-direction: column;
      .our-affiliates-section__cta,
      .our-affiliates-section__cta-text {
        margin: auto;
      }
      .our-affiliates-section__cta {
        margin-top: 2rem;
        padding: 0;
      }
    }
  }

  .recent-reviews-section__grid {
    grid-template-columns: 1fr;
    grid-template-areas:
      'review1'
      'review2'
      'review3'
      'review4 ';

    .recent-reviews-section__review-1,
    .recent-reviews-section__review-2,
    .recent-reviews-section__review-3,
    .recent-reviews-section__review-4 {
      width: 100%;
      max-width: 100%;
      flex-direction: column;

      .recent-reviews-section__review-1-image,
      .recent-reviews-section__review-2-image,
      .recent-reviews-section__review-3-image,
      .recent-reviews-section__review-4-image {
        max-width: 100%;
        width: 100%;
      }

      .recent-reviews-section__review-1-text,
      .recent-reviews-section__review-2-text,
      .recent-reviews-section__review-3-text,
      .recent-reviews-section__review-4-text {
        left: 0;
        bottom: 80px;
        margin: auto;
        margin-bottom: -80px;
        max-width: 90%;
      }
    }
  }

  .client-stats-container {
    flex-direction: column;
    .my-8 {
      max-width: 100%;
    }
  }
}

.realtor-reviews-you-can-trust {
  .mobile-btn {
    display: none;
  }
}

.rer-register-account-header {
  background-color: $white;
  background-image: url('../../assets/images/concierge_requests/porch-background.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top;
  min-height: 100vh;

  .rer-register-account-header__form {
    max-width: 30rem;
    .MuiOutlinedInput-root {
      background: $white;
    }

    .MuiFormHelperText-sizeMedium {
      color: $white;
    }
  }
}

@media (max-width: 752px) {
  .real-estate-rewards__header-section {
    padding-top: 4vh;
  }

  .realtor-reviews-you-can-trust {
    flex-direction: column;

    .desktop-btn {
      display: none;
    }

    .mobile-btn {
      display: block;
    }

    .realtor-card-image {
      max-width: 30rem;
      text-align: center;
      padding-top: 0;
    }
  }
}

@media (max-width: 480px) {
  .our-affiliates-section__cta-nav {
    top: 64px;
  }
}

#sidebar {
  position: sticky;
  text-align: center;
  top: 0;
  width: 320px;
}

.vector-icon {
  fill: #fff;
  height: auto;
  width: 25px;
  font-size: 2rem;

  &.drawer-list-icon {
    margin-left: 10px;
    width: 14px;
  }

  &.black {
    fill: rgba(0, 0, 0, 0.87);
  }

  &.blue {
    fill: $brand-blue;
    color: $brand-blue;
  }

  &.cancel-search-icon {
    fill: $cornell;
    height: 25px;
  }

  &.vector-icon--dropdown-arrow {
    height: 20px;
    width: unset;
  }

  &:hover {
    cursor: pointer;
  }

  &.small {
    font-size: 1.25rem;
  }
}

.vector-icon__drawer-item {
  fill: black;
  width: 12px;
  height: auto;
}

.members-headline {
  color: $white;
  max-width: 850px;
  text-align: center;

  &.welcome-page-only {
    display: none;
  }

  p {
    font-size: 18px;
  }
}

.welcome-member {
  background-color: #f2f2f2;
  padding-top: 8vh;
  padding-bottom: 5vh;
  .welcome-member__progress-bar-left {
    background-color: $cornell;
  }

  .welcome-member__progress-bar-right {
    background-color: #ddd;
  }

  .welcome-member__progress {
    @media (max-width: 752px) {
      flex-direction: column;

      .mr-12 {
        margin-right: 0;
        margin-bottom: 1rem;
      }

      .ui.button {
        margin: auto;

        @media (max-width: 530px) {
          max-width: 100%;
        }
      }
    }
  }
}

.search-menu--alternate {
  max-width: 350px;
  width: 80%;
}

.MembersHeader {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(flex-start);
  @include align-items(center);
  background-color: #2b3c8a;
  min-height: 225px;
  padding: 30px 10px;
  padding-top: 0;
}

body.homecontroller.welcome {
  .MembersHeader {
    background-color: transparent;
  }
}

body.homecontroller.welcome {
  background-color: $white;
}

body.conciergerequestscontroller.thanks {
  background-color: $white;
}

.members-logo {
  height: auto;
  width: 190px;
}

.members-header {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(flex-start);
  @include align-items(center);
  background-color: $white;
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.61);
  position: sticky;
  top: 0;
  z-index: 10;
}

.TopLineNav {
  @include flex(0, 0, 100%);
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(space-between);
  @include align-items(center);
  margin-bottom: 50px;
  padding: 10px 35px;
  width: 100%;

  .mdc-menu-surface {
    width: 160px;
  }

  &.home-nav {
    .mdc-menu-surface {
      width: fit-content;

      .mdc-list-item {
        color: $charcoal;
        &:visited {
          color: $charcoal;
        }
      }
    }
  }

  .nav-menu-link {
    color: $charcoal;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      color: lighten($charcoal, 30%);
      text-decoration: none;
    }
  }

  .home-menu-link {
    color: $charcoal;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      color: lighten($charcoal, 30%);
      text-decoration: none;
    }
  }

  .nav-logo-container {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(flex-start);
    @include align-items(center);
    @include flex(-1, 0, 25%);
  }

  .nav-menu-container {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(space-around);
    @include align-items(center);
    @include flex(1, 0, 72%);

    &.header-keyword-search {
      @include flex(0, 0, 65%);
      @include justify-content(flex-start);
    }

    &.realtors-nav {
      .mdc-menu-surface {
        width: fit-content;
        .mdc-list-item {
          white-space: nowrap;
        }
      }
    }
  }

  .nav-menu-container-mobile {
    display: none;
  }

  &.top-line-nav--members {
    margin: auto;
    max-width: 1200px;

    .nav-menu-container {
      .mdc-list-item {
        &:hover {
          background: $white;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    margin-bottom: 20px;
    padding: 10px;

    .nav-menu-container {
      @include flex(0, 0, 0%);
      display: none;
    }

    .nav-menu-container-mobile {
      @include flex(0, 1, 72%);
      @include flexbox;
      @include flex-direction(row);
      @include justify-content(flex-end);
      @include align-items(center);

      .mdc-menu-surface--anchor {
        margin-right: 25px;
      }
    }
  }
}

.SearchMenu {
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(space-between);
  @include align-items(center);
  @include flex(0, 0, 100%);
  background-color: #fff;
  margin-bottom: 20px;
  max-width: 800px;
  padding: 5px;
  width: 90%;

  .vertical-split {
    background-color: rgb(212, 212, 212);
    height: 35px;
    margin: 0;
    width: 1.7px;
  }

  .search-form-inputs {
    @include flex(1, 0, 25%);
    border: 0;
    border-right: 1px solid rgba(212, 212, 212, 0.3);
    font-size: 14px;
    margin-right: 10px;
    max-width: 250px;
    min-width: 190px;
    padding: 10px;
  }

  .ui.button {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(space-between);
    @include align-items(center);
    font-size: 16px;
    margin-right: 0;
  }

  .vector-icon {
    margin-left: 10px;
    width: 22px;
  }

  @media (max-width: 825px) {
    @include flex-direction(column);
    background-color: rgba(0, 0, 0, 0);

    .search-menu-pcs-location {
      background-color: #fff;
      max-width: 360px;
      width: 100%;
    }

    .vertical-split {
      display: none;
    }
  }
}

.search-menu-pcs-location {
  @include flex(1, 0, 25%);
  max-width: 250px;
  background-color: #fff;

  &.provider-page {
    max-width: 100%;
    width: 100%;
    border-radius: 5px;
    margin-top: 2rem;

    .ui.icon.input {
      border-radius: 5px;
      padding-right: 10px;
    }
  }

  .ui.icon.input {
    width: 100%;

    input {
      border: 0;
      padding: 0.67861em 1em !important;
      width: calc(100% - 16px);
    }

    .search-menu-pcs-location-icon {
      box-sizing: content-box;
      fill: $silver-sand;
      height: 38px;
      overflow: visible;
      width: 16px;
    }
  }

  @media (max-width: 825px) {
    margin-bottom: 20px;
    padding-right: 10px;

    .ui.icon.input {
      @include align-items(center);
    }
  }
}

body {
  &.infocontroller,
  &.bahcalculatorcontroller,
  &.realestaterewardscontroller,
  &.memberscontroller,
  &.favoritescontroller,
  &.reviewscontroller,
  &.thankscontroller,
  &.newproviderreviewscontroller,
  &.gradescontroller {
    .MembersHeader {
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url('../../assets/images/front-porch-small.jpg');
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }

    .SearchMenu {
      @include flex-direction(column);
      background-color: transparent;
      max-width: 1440px;

      .pcs-search__vendor-section {
        display: none;
      }

      &.pb-24 {
        padding-bottom: 6rem;
      }
    }
  }
}

body.homecontroller.welcome {
  .SearchMenu {
    @include flex-direction(column);
    background-color: transparent;
    max-width: 1250px;
  }
}

body.conciergerequestscontroller.thanks {
  .SearchMenu {
    @include flex-direction(column);
    background-color: transparent;
    max-width: 1250px;
  }
}

.search-menu-pcs-location__w-full.ui.search {
  max-width: 800px;
  .ui.icon.input {
    width: 100%;
    .prompt {
      width: 100%;
      border: none;
      border-radius: 5px;
      margin: auto;
      max-width: 800px;
      height: 4rem;
    }
  }
}

.review-category-pcs-search {
  .search-menu-pcs-location__w-full.ui.search {
    max-width: 100%;
    .ui.icon.input {
      width: 100%;

      ::placeholder {
        color: $darker-grey;
      }

      .prompt {
        width: 100%;
        border: 1px solid #ddd;
        background-color: #f2f2f2;
        color: $charcoal;
        max-width: 100%;
        height: 2rem;
        padding: 1.25rem;
      }
    }
  }
}

.mdc-drawer--temporary .mdc-drawer__content.mdc-list .mdc-list-item {
  font-size: 16px;
}

.provider-search-menu {
  @include flex(1, 0, 25%);
  margin-right: 15px;
  max-width: 450px;

  &.drawer-search {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    margin: 20px auto 0;
    max-width: 240px;
  }

  .ui.icon.input {
    font-size: 16px;
    width: 100%;
    border: solid $darker-grey 1px;
    border-radius: 2px;

    input {
      border: 0;
      border-radius: 5px;
      padding: 0.67861em 1em !important;
      text-align: center;
      width: 100%;
    }

    input::placeholder {
      color: $darker-grey;
    }
  }
}

.mdc-drawer__content {
  .mdc-button--raised.submit-reviews-drawer-button:not(:disabled) {
    background-color: $summer-sky;
    margin-left: 10px;
  }
}

.mdc-tab-bar.provider-tabs-scroller {
  padding: 0;
  position: relative;

  .mdc-tab.mdc-tab--active {
    background-color: #fff;

    .mdc-tab__content > .rmwc-icon.material-icons > .provider-tabs-icon {
      fill: var(--mdc-theme-primary, #3949ab);
    }

    .mdc-tab__text-label > span {
      color: var(--mdc-theme-primary, #3949ab) !important;
    }
  }

  @media (max-width: 1024px) {
    padding-left: 30px;
    padding-right: 0;
  }
}

.provider-tabs-icon {
  cursor: pointer;
  fill: #fff;
  height: 24px;
  width: 24px;
}

.ProviderTabs {
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(center);
  @include align-items(center);
  background-color: $brand-blue;
  margin-bottom: 10px;
  width: 100%;

  .provider-scroller-container {
    margin: 0 10px;
    max-width: 1200px;
    width: 80%;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
}

.SortFilterMenu {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(space-between);
  @include align-items(center);
  background-color: #fff;
  margin: auto;
  max-width: 750px;
  width: 90%;
  margin-top: 1rem;

  .ui.button {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(space-between);
    @include align-items(center);
    font-size: 16px;
    margin-right: 0;
  }

  .vector-icon {
    fill: #fff;
    height: auto;
    margin-left: 10px;
    width: 22px;

    &:hover {
      cursor: pointer;
    }
  }

  .sort-filter-menu-toggle {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    width: 100%;
    font-size: 16px;
  }

  .sort-filter-menu-inputs {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align-items(center);
    margin-top: 10px;
    max-width: 500px;
    padding: 10px 20px;
    width: 100%;

    .mdc-select {
      margin-bottom: 10px;
      width: 100%;
    }

    .mdc-form-field {
      margin: 15px 0;
    }

    hr {
      border: 0;
      border-bottom-color: rgba(0, 0, 0, 0.12);
      border-bottom-style: solid;
      border-bottom-width: 1px;
      margin: 20px 0 4px;
      width: 100%;
    }
  }
}

.open-no-review-modal {
  position: fixed;
  height: 14px;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.members-edit-page {
  background-image: url('../../assets/images/suburb-overview.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .members-edit-container {
    max-width: 54rem;

    .members-edit-form {
      border-radius: 5px;
    }

    .member-profile-navigation {
      width: 100%;

      ul {
        margin: auto;
      }
    }

    .rounded {
      margin-bottom: 0;
    }
  }

  @media (max-width: 480px) {
    padding: 0;
  }
}

.cash-back__house-image {
  max-width: 50rem;
  img {
    filter: drop-shadow(13px 0 13px darken($color: $brand-blue, $amount: 10));
    -webkit-filter: drop-shadow(
      13px 0 13px darken($color: $brand-blue, $amount: 10)
    );
  }
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
}

.cash-back__header-description {
  max-width: 36rem;

  .cash-back__cta {
    width: 100%;
    max-width: 12rem;
    height: 3.5rem;
  }

  .wizard__wizard-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: center;
  }

  .rebates-wizard {
    background-color: $white;
    border-radius: 10px;
    margin: 0;
    color: $default;
    .mui-state-select {
      fieldset {
        display: none;
      }
    }
  }

  .rebates-wizard__step-two {
    border-radius: 10px;
  }
}

.cash-back__disclosure {
  font-size: 12px;
  color: $white;
}

.cash-back__realty-logos {
  max-width: 34rem;
  border-right: solid 2px $darker-grey;
}

.cash-back__realogy {
  max-width: 28rem;
}

.cash-back__how-it-works-hr {
  border-bottom: dashed 2px #aacddc;
  max-width: 75%;
}

.cash-back__how-it-works {
  position: relative;
  top: -2rem;

  .cash-back__how-it-works-mobile-border {
    display: none;
  }

  .cash-back__how-it-works-step {
    max-width: 16rem;
    z-index: 1;

    .step-number {
      width: 4rem;
      line-height: 4rem;
      border-radius: 50%;
      font-size: 32px;
      color: $white;
      background-color: $brand-blue;
    }
  }
}

.cash-back__rewards-text {
  max-width: 450px;
}

.cash-back__free-enrollment {
  max-width: 40rem;

  .cash-back__chevrons {
    max-width: 3rem;
  }
}

.logos-row-1 {
  display: grid;
  grid-template-columns: 0.75fr 1fr 0.75fr;
  img {
    place-self: center;
  }
}

.logos-row-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  img {
    place-self: center;
  }
}

.cash-back__mobile-show {
  display: none;
}

.pcsgrades-a-graded-section {
  .pcsgrades-a-graded-logo {
    max-width: 20rem;
    border-right: solid $default 1px;

    &.no-border {
      border-right: none;
    }
  }

  .pcsgrades-a-graded-text {
    max-width: 40rem;
  }
}

.pcsgrades-a-graded-section {
  .pcsgrades-a-graded-section__text {
    max-width: 40rem;
  }

  .pcsgrades-a-graded-section__image {
    max-width: 25rem;
  }
}

@media (max-width: 780px) {
  .cash-back__header-container {
    flex-direction: column;

    .cash-back__header-description {
      max-width: 100%;
    }

    .cash-back__cta-container {
      padding-left: 0;
      padding-right: 0;
    }

    .cash-back__house-image {
      max-width: 40rem;
      margin: auto;

      img {
        padding: 0;
      }
    }

    .cash-back__house-image--fb {
      margin-top: 2rem;
    }
  }

  .cash-back__how-it-works-hr {
    display: none;
  }

  .cash-back__how-it-works {
    flex-direction: column;

    .cash-back__how-it-works-mobile-border {
      display: block;
      height: 16rem;
      position: absolute;
      border-left: dashed #aacddc;
      top: 65px;
      left: 55px;
    }

    .cash-back__how-it-works-step {
      text-align: left;
      display: flex;
      max-width: 22rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
      margin-left: 2rem;

      .step-number {
        max-width: 4rem;
        width: 100%;
        text-align: center;
      }

      p {
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }

  .cash-back__mobile-hide {
    display: none;
  }

  .cash-back__mobile-show {
    display: block;

    &.realogy-description {
      max-width: 47rem;
      margin: auto;
    }
  }

  .cash-back__enrollment-container {
    flex-direction: column;

    .cash-back__free-enrollment {
      max-width: 100%;
      padding-bottom: 0;
    }

    .cash-back__blog-preview {
      padding: 0;
    }
  }

  .cash-back__about-us-container {
    flex-direction: column-reverse;

    .cash-back-about-us-image {
      padding: 0;
    }
  }

  .pcsgrades-a-graded-section {
    flex-direction: column;

    .pcsgrades-a-graded-section__image {
      margin: 0;
    }
    .pcsgrades-a-graded-section__text {
      margin: auto;
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 640px) {
  .cash-back__calc-container {
    flex-direction: column;
  }

  .cash-back__reviews-container {
    flex-direction: column;
    padding: 0;

    .cash-back-review-card {
      margin-left: 0;
      margin-right: 0;
      display: flex;
      height: 24rem;

      &:nth-of-type(1) {
        background-image: url('../../assets/images/cash_back/mario-h.jpg');
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }

      &:nth-of-type(2) {
        background-image: url('../../assets/images/cash_back/gretchen-o.jpg');
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }

      .text-center {
        display: none;
      }

      .cash-back__review-comment {
        margin: 0 2rem;
        margin-top: auto;
        padding: 1rem;
        background-color: #f2f5f7;
        opacity: 0.8;
      }
    }
  }

  .rebates-wizard {
    .rebates-lead-submit {
      width: 100%;
    }
  }

  .rebates-wizard__step-two {
    .wizard__wizard-nav {
      flex-direction: column;

      .rebates-lead-submit {
        margin-bottom: 1rem;
      }
    }
  }

  .wizard-buttons-container {
    flex-direction: column;
    .rebate-wizard-call-number {
      margin: auto;
      margin-top: 20px;
      text-align: center;
    }
  }
}

@media (max-width: 480px) {
  .cash-back__realty-logos {
    padding-right: 1rem;

    .p-4 {
      padding: 0.5rem;
    }
  }

  .cash-back__realogy {
    padding-left: 1.5rem;
  }
}

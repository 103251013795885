%headline-section {
  border-radius: 10px;
  height: 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  width: 150px;

  @media only screen and (max-width: 480px) {
    width: 125px;
  }
}

.empty-results {
  @include flexbox;
  @include flex-direction(column);
  @include flex-wrap(nowrap);
  @include justify-content(space-between);
  @include align-items(center);
  margin-bottom: 20px;

  p {
    font-size: 2rem;
  }

  .empty-results-cta {
    font-size: 1.075rem;
  }
}

.badges-legend {
  @include flexbox;
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  list-style: none;
  margin: auto auto 10px;
  max-width: 420px;

  .legend-item {
    @include flexbox;
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(center);
    @include flex(0, 0, 33%);
  }

  .badge {
    height: auto;
    width: 20px;
  }
}

.providers-section {
  @include flexbox;
  @include flex-direction(column);
  @include flex-wrap(nowrap);
  @include justify-content(space-between);
  @include align-items(center);
  margin-bottom: 15px;
}

.provider-cards {
  @include flexbox;
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include justify-content(space-around);
  @include align-items(flex-start);
  margin-bottom: 15px;
  width: 100%;
}

.ProviderCard {
  margin-bottom: 15px;
  width: 330px;

  &.declined {
    background-color: $muted-grey;

    .declined-card-text {
      @include flexbox;
      @include align-items(center);
      min-height: 100px;
    }

    .provider-card-divider {
      border-color: #000;
    }
  }

  .provider-card-details {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(space-between);
    @include align-items(flex-start);
    padding: 5px 0 1rem;

    h3,
    p {
      margin-bottom: 5px;
    }
  }

  .card-label {
    color: rgba(0, 0, 0, 0.66);
    font-size: 15px;
    letter-spacing: 0.07em;
  }

  .details-container {
    @include flex(0, 1, 75%);
    min-height: 100px;
  }

  .badges {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align-items(center);
    height: 100px;
    width: 30px;
  }

  .material-icons.mdc-icon-button__icon > .provider-icon-button {
    &.solid-icon {
      fill: $thunderbird;
    }
  }

  .provider-card-divider {
    border-color: #dcdcdc;
    margin: 5px 0 15px;
  }

  .mdc-card__primary-action {
    padding: 10px 10px 0;
  }

  .mdc-card__action.mdc-card__action--button.mdc-button {
    font-size: 16px;
  }

  .headline-container {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(space-between);
    @include align-items(center);
    height: 150px;
  }

  .avatar-container {
    @extend %headline-section;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .grade-container {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    @extend %headline-section;
    background-color: #dcdcdc;
    color: rgba(#000, 0.8);
    font-size: 24px;

    &.A,
    &.A\+ {
      background-color: $grade-a-green;
      color: $white;
    }

    &.B,
    &.B\+ {
      background-color: $grade-b-blue;
      color: $white;
    }

    &.C,
    &.C\+ {
      background-color: $grade-c-yellow;
      color: rgba(#000, 0.8);
    }

    &.D,
    &.D\+ {
      background-color: $grade-d-orange;
      color: $white;
    }

    &.F,
    &.F\+ {
      background-color: $grade-f-red;
      color: $white;
    }

    &.none .letter-grade {
      font-size: 60px;
    }

    .letter-grade {
      font-size: 60px;
      margin-bottom: 14px;
    }
  }

  @media only screen and (max-width: 480px) {
    width: 280px;

    .headline-container {
      height: 125px;
    }
  }
}
